import {HttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {ServiceContainer} from "../ServiceContainer";
import QueryService from "../QueryService";

export default class LinkProvider {
    public static getHttpLink() {
        return new HttpLink({
            uri: process.env.REACT_APP_BACKEND_ADDRESS + "/graphql",
            credentials: 'include',
        })

    }

    public static getContextLink(httpLink: HttpLink, token: string | null, urlPath: string) {
        let contextLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    ...(token ? { 'X-AUTH-TOKEN': token } : {}),
                    'URL-PATH': urlPath,
                },
            };
        });
        return contextLink.concat(httpLink);
    }

    public static getCurrentLink() {
        const token = ServiceContainer.resolve<QueryService>('QueryService').token
        const urlPath = window.location.href;
        return LinkProvider.getContextLink(LinkProvider.getHttpLink(), token, urlPath);
    }
}