import React from 'react'
import {changeActiveModalNode} from "../../../reducers/node-modal";
import {Modal} from "bootstrap";
import {useDispatch, useSelector} from "react-redux";
import AddNewImg from "./img/add-new.svg"

// const TMP => () => {}
const AddNewBlock = () => {
    const dispatch = useDispatch();
    const nodes = useSelector((state) => state.nodes.present.value);
    return (
        <div className='min-vh-100 d-flex align-items-center justify-content-center'>
            <div className={'container text-center'} style={{marginTop: 100, marginBottom: 100}} >
                <div className='text-center mb-4'>
                    <img src={AddNewImg} alt={'add new block'} className="pb-2"/>
                </div>
                <div onClick={
                    () => {
                        dispatch(changeActiveModalNode(nodes[0]));
                        Modal.getOrCreateInstance('#container-to-add-modal').show();
                    }
                } className={'btn btn-primary py-2 px-5 fw-bold'}><i className={'bi bi-plus me-2'}></i>Create Block</div>
            </div>
        </div>
    )
}

export default AddNewBlock
