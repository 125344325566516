import React from 'react'
import EditModalSizeInput from "../ui/input/EditModalSizeInput";
import {
    ChangeNodeFunction,
    ChangeStyleFunction, deleteCustomStyleByName,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import ContainerSize, { ContainerSizeProperty } from "./ContainerSize";
import BorderSpacing, {StyleSettingsProps as BorderSpacingProps} from "./BorderSpacing";
import {CustomStyleField} from "../../../types/CustomStyleField";
import Spacing, { SpacingProperty } from "./Spacing";
import { AlignmentContainer, InputProperty } from './Aligment/AlignmentContainer';
import {PageNode} from "../../../types/PageNode";
import Opacity, { InputWidthPostfixProperty } from "./Opacity";
import CornerRadius, { CornerRadiusProps } from './CornerRadius';
import { ComponentArrayItem, StyleCheckKeys } from '../../../types/StyleCheckKeys';
import useSortedComponents from '../../../hooks/useSortedComponents';
import { MobileStyleRadioButton } from '../ui/MobileStyleRadioButton';

export interface StyleSettingsProps {
    editableNode: PageNode,
    changeNodeFunction: ChangeNodeFunction,
    changeStyleFunction: ChangeStyleFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    isMobileViewDimension: boolean,
    imageSrc: string
}

type СomponentItemType = 
| ContainerSizeProperty 
| InputProperty 
| SpacingProperty 
| BorderSpacingProps 
| InputWidthPostfixProperty 
| CornerRadiusProps;

const sortOrder: StyleCheckKeys[] = [
    StyleCheckKeys.ContainerSize,
    StyleCheckKeys.Alignment,
    StyleCheckKeys.CornerRadius,
    StyleCheckKeys.Gap,
    StyleCheckKeys.Opacity,
    StyleCheckKeys.Border,
    StyleCheckKeys.Spacing
  ];

// Компонент с типизированными входными параметрами
const StyleSettings: React.FC<StyleSettingsProps> = ({
                                                         editableNode,
                                                         isMobileViewDimension,
                                                         changeNodeFunction,
                                                         changeStyleFunction,
                                                         deleteStyleByName,
                                                         imageSrc,
                                                     }) => {
    let isMobileStyleContainerSize: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleContainerSize = editableNode.isMobileStyleContainerSize
    }

    const changeBorderRadiusCustomStyle = (editedNode: PageNode, isMobileStyle:boolean, isMixed:boolean): PageNode => {
        let tmpNode = {...editableNode}

        if (isMobileStyle) {
            tmpNode.isMobileMixedBorderRadius = isMixed
        } else {
            tmpNode.isMixedBorderRadius =  isMixed
        }

        if (isMixed) {
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderRadius, tmpNode, isMobileStyle)
        } else {
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderTopRightRadius, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderTopLeftRadius, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderBottomRightRadius, tmpNode, isMobileStyle)
            tmpNode = deleteCustomStyleByName(CustomStyleField.BorderBottomLeftRadius, tmpNode, isMobileStyle)
        }

        changeNodeFunction(tmpNode)
        return tmpNode
    }

    let componentsArray: ComponentArrayItem<СomponentItemType>[] = [
        {
          condition: true,
          type: StyleCheckKeys.ContainerSize,
          component: ContainerSize,
          props: {
            isMobileStyleContainerSize,
            deleteStyleByName,
            changeNodeFunction,
            changeStyleFunction,
            editableNode,
            imageSrc,
          },
        },
        {
          condition: editableNode.tagName === 'div',
          type: StyleCheckKeys.Alignment,
          component: AlignmentContainer,
          props: {
            isMobileEditMode: isMobileStyleContainerSize,
            editableNode,
            changeNodeFunction,
          },
        },
        {
            condition: true,
            type: StyleCheckKeys.CornerRadius,
            component: CornerRadius,
            props: {
                deleteStyleByName,
                changeStyleFunction,
                editableNode,
                isMobileStyleContainerSize,
                changeBorderRadiusCustomStyle,
            },
        },
        {
            condition: editableNode.tagName === 'div',
            type: StyleCheckKeys.Gap,
            component: EditModalSizeInput,
            props: {
                deleteStyleByName,
                changeStyleFunction,
                editableNode,
                optionValue: CustomStyleField.Gap,
                labelName: "Gap (px)",
                isMobileStyle: isMobileStyleContainerSize
            },
        },
        {
            condition: true,
            type: StyleCheckKeys.Opacity,
            component: Opacity,
            props: {
                labelName: "Opacity (%)",
                deleteStyleByName,
                changeStyleFunction,
                editableNode,
                optionValue: CustomStyleField.Opacity,
                isMobileStyle: isMobileStyleContainerSize,
            },
        },
        {
            condition: true,
            type: StyleCheckKeys.Border,
            component: BorderSpacing,
            props: {
                isMobileViewDimension,
                editableNode,
                changeNodeFunction,
                isMobileStyleContainerSize,
            },
        },
        {
            condition: true,
            type: StyleCheckKeys.Spacing,
            component: Spacing,
            props: {
                isMobileStyleContainerSize,
                deleteStyleByName,
                changeNodeFunction,
                editableNode,
                isMobileViewDimension,
                changeStyleFunction
            },
        },
    ];

    componentsArray = useSortedComponents(componentsArray, sortOrder);

    return(
        <>
            <div className="row g-3 mt-0">
                <div className={'d-flex'}>
                    <h5 className={'me-auto my-0'}>
                        {imageSrc ? "Icons Settings" : "Settings"}
                    </h5>
                    <MobileStyleRadioButton
                        currentStatus={isMobileStyleContainerSize}
                        onChange={(state) => {
                            editableNode.isMobileStyleContainerSize = state;
                            changeNodeFunction(editableNode);
                        }}
                    />
                </div>
                {componentsArray.map(({ condition, type, component: Component, props }, index) => {
                    if (!condition) return null;
                    if  (type === StyleCheckKeys.ContainerSize || type === StyleCheckKeys.CornerRadius || type === StyleCheckKeys.Border) {
                        return <Component {...props} key={index} />
                    }
                    if (type === StyleCheckKeys.Spacing) {
                        return <div className="col-12" key={index}><Component {...props} /></div>
                    }
                    return <div className="col-md-6" key={index}><Component {...props} /></div>;
                })}
            </div>
        </>
    )
}

export default StyleSettings