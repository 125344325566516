import {useDispatch, useSelector} from "react-redux";
import {moveBack, moveForward} from "../../reducers/nodes";
import ContextMenuModals from "./modals/ContextMenuModals";
import ContainerAddModal from "./add-element/container-add-modal";
import {changeActiveModalNode} from "../../reducers/node-modal";
import ShoppingCartModal from "./modals/shopping-cart-modal";
import CartCircle from "./CartCircle";

// deprecated
// const TMP => () => {}
const ContextMenu = () => {
    const isAdvancedMode = useSelector((state) => state.appStorage.isAdvancedMode);
    const activeNode = useSelector((state) => state.nodeModal.value)
    const dispatch = useDispatch();
    const shoppingCart = useSelector(state => state.shoppingModal.value)

    function moveForwardAction() {
        dispatch(moveForward({node: activeNode}));
    }

    function moveBackAction() {
        dispatch(moveBack({node: activeNode}));
    }

    return <>
        {(activeNode) &&
            <>
                <div className="modal" id="contextModal" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Choose Action</h5>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={() => dispatch(changeActiveModalNode(null))}
                                >
                                    Close
                                </button>
                            </div>
                            <div className="modal-body">
                                <nav className="nav flex-column nav-pills">
                                    {/*<a className="nav-link"*/}
                                    {/*   data-bs-toggle="modal"*/}
                                    {/*   data-bs-target="#container-to-add-modal"*/}
                                    {/*   href="#">Add element</a>*/}

                                    {/*<a className="nav-link" href="#">Link</a>*/}
                                    {/*<a className="nav-link disabled">Disabled</a>*/}
                                    {/*<a className="nav-link"*/}
                                    {/*   onClick={*/}
                                    {/*       () => {*/}
                                    {/*           Modal.getOrCreateInstance('#edit-block-modal').show()*/}
                                    {/*       }*/}
                                    {/*   }*/}
                                    {/*   data-bs-dismiss="modal"*/}
                                    {/*   href="#">Edit element</a>*/}
                                    {(activeNode.id !== '1') &&
                                        <>

                                            <span className="nav-link" data-bs-dismiss="modal"
                                                  onClick={moveForwardAction}>Move forward</span>
                                            <span className="nav-link" data-bs-dismiss="modal"
                                                  onClick={moveBackAction}>Move back</span>
                                            {/*<a className="nav-link text-danger" href="#" data-bs-dismiss="modal"*/}
                                            {/*   onClick={*/}
                                            {/*       () => {*/}
                                            {/*           if (window.confirm("Are you sure?") === true) {*/}
                                            {/*               deleteNodeActiveNode()*/}
                                            {/*           }*/}
                                            {/*       }}>Delete</a>*/}
                                        </>
                                    }

                                </nav>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={() => dispatch(changeActiveModalNode(null))}
                                >
                                    Close
                                </button>
                                {/*<button type="button" className="btn btn-primary">Save changes</button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        <ContextMenuModals isAdvancedMode={isAdvancedMode}/>
        <ContainerAddModal/>
        <ShoppingCartModal/>
        {shoppingCart.length ? <CartCircle/>:<></>}

    </>
}

export default ContextMenu