import {createSlice} from "@reduxjs/toolkit";


export enum ActiveTab {
    CONTENT = 'content',
    STYLE = 'style',
    ACTION = 'action'
}

interface tabState {
    activeTab: string
}

const initialState: tabState = {
    activeTab: ""
}

export const tabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            if(Object.values(ActiveTab).includes(action.payload)){
                state.activeTab = action.payload;
            }else{
                state.activeTab = ActiveTab.STYLE;
            }
        },
    }
})
export const {setActiveTab} = tabSlice.actions
export default tabSlice.reducer;