import React from "react";
interface ErrorItem {
  message: string;
  extensions?: { field?: string };
}

interface NetworkError {
  result?: {
    errors?: ErrorItem[];
  };
}

interface CustomError extends Error {
  networkError?: NetworkError;
}

interface ErrorProps {
  networkError?: NetworkError | CustomError;
}

const ApolloNetworkErrors: React.FC<ErrorProps> = ({ networkError }) => {
  const errors: ErrorItem[] =
    networkError && "result" in networkError && networkError.result?.errors
      ? networkError.result.errors
      : [];


  if (
    !networkError ||
    !("result" in networkError) ||
    !networkError.result?.errors
  ) {
    return null;
  }

  return (
    <div>
      {errors.map((item, index) => (
        <div key={index} className={index === 0 ? "" : "mt-2"}>
          {item?.extensions?.field && <span>{item?.extensions?.field}: </span>}
          {JSON.stringify(item.message, null, 2)}
        </div>
      ))}
    </div>
  );
};

export default ApolloNetworkErrors;
