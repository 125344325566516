import {ScreenShotProcessor} from "./ScreenShotService";
import {
    UpdatePageScreeShotDocument, UpdatePageScreeShotMutation, UpdatePageScreeShotMutationVariables
} from "./PageScreenShotProcessor.hooks";
import {ServiceContainer} from "../ServiceContainer";
import QueryService from "../QueryService";
import {PageScreenShotService} from "./PageScreenShotService";
import {ApolloClient, NormalizedCacheObject} from "@apollo/client";

export class PageScreenShotProcessor implements ScreenShotProcessor {
    private pageId: number;
    private folderId: number | undefined;
    private apolloClient: ApolloClient<NormalizedCacheObject>;

    public constructor(pageId: number, apolloClient: ApolloClient<NormalizedCacheObject>, folderId: number | undefined) {    
        this.pageId = pageId
        this.folderId = folderId
        this.apolloClient = apolloClient
    }

    getNodeId(): string {
        return '1'
    }

    onScreenShotAction(): Promise<string> {
        return PageScreenShotService.takePageScreenShot()
    }

    onScreenShotReady(result: string): Promise<string> {
        const showLog = ServiceContainer.resolve<QueryService>('QueryService').isDevMode

        return new Promise((resolve) => {
            this.apolloClient.mutate<UpdatePageScreeShotMutation, UpdatePageScreeShotMutationVariables>({
                mutation: UpdatePageScreeShotDocument,
                variables: {id: this.pageId, image: result, ...(this.folderId ? { folderId: this.folderId } : {})}
            })
                .then(value => {
                    return resolve(value.data.updatePage.image)
                })
                .catch(reason => {
                    if (showLog) {
                        console.error("Ошибка отправки скриншота на сервер: ", reason);
                    }
                })

        })
    }

}