import React from 'react';
import {CustomStyleField} from "../types/CustomStyleField";
import {NodeType, PageNode} from "../types/PageNode";

interface CustomStylesProps {
    node: PageNode;
    isMobileViewDimension: boolean;
}

// Компонент с типизированными входными параметрами
const CustomStyles: React.FC<CustomStylesProps> = ({node, isMobileViewDimension}) => {

    const styleToString = (style: any) => {
        let returnString = '';

        Object.keys(style).forEach((key) => {
            const isWidthHeightBorderPaddingMargin =
                key === CustomStyleField.Width || 
                key === CustomStyleField.Height || 
                key === CustomStyleField.BorderRadius ||
                key === CustomStyleField.BorderTop || 
                key === CustomStyleField.BorderBottom || 
                key === CustomStyleField.BorderRight ||
                key === CustomStyleField.BorderLeft || 
                key === CustomStyleField.Border || 
                key === CustomStyleField.BorderBottomRightRadius || 
                key === CustomStyleField.BorderBottomLeftRadius || 
                key === CustomStyleField.BorderTopLeftRadius || 
                key === CustomStyleField.BorderTopRightRadius || 
                key === CustomStyleField.Gap || 
                key === CustomStyleField.Opacity || 
                key === CustomStyleField.Padding || 
                key === CustomStyleField.PaddingLeft || 
                key === CustomStyleField.PaddingRight ||
                key === CustomStyleField.PaddingTop || 
                key === CustomStyleField.PaddingBottom || 
                key === CustomStyleField.Margin ||
                key === CustomStyleField.MarginBottom ||
                key === CustomStyleField.MarginTop || 
                key === CustomStyleField.MarginRight || 
                key === CustomStyleField.MarginLeft;

            const isBackgroundColor = 
                key === CustomStyleField.Background || 
                key === CustomStyleField.BorderColor || 
                key === CustomStyleField.Color;
    
            const isBackgroundImage =
                key === CustomStyleField.BackgroundImage || 
                key === CustomStyleField.BackgroundColor || 
                key === CustomStyleField.BackgroundSize || 
                key === CustomStyleField.ObjectFit;
    
            const isTransformOrBackgroundColorOrObjectFit =
                key === CustomStyleField.Transform || 
                key === CustomStyleField.BackgroundColor || 
                key === CustomStyleField.ObjectFit;
    
            if ((isWidthHeightBorderPaddingMargin && node.isMobileStyleContainerSize) ||
                (isBackgroundColor && node.isMobileStyleColor) ||
                (isBackgroundImage && node.isMobileStyleBackground) ||
                (isTransformOrBackgroundColorOrObjectFit && node.isMobileStyleImage)) {
                returnString += `${key.split(/(?=[A-Z])/).join('-').toLowerCase()}:${style[key]}!important;`;
            }
        });
    
        return returnString;
    };

    const styleToStringWithAction = (style: any, action: string): string => {
        let returnString = ''
        Object.keys(style).map((key) => {
            let name = ''
            if (action === 'hover') {
                if (key === CustomStyleField.HoverColor) {
                    name = 'color'
                }
                if (key === CustomStyleField.Hover) {
                    name = 'background-color'
                }
            }
            returnString += name + ':' + style[key] + '!important;'
        })
        return returnString
    }

    const generateStyleTag = (styleContent: string) => (
        <style dangerouslySetInnerHTML={{__html: styleContent}}></style>
    );

    const mobileStyle = node.customStyleMobile
        ? `@media (max-width: 576px) {
            #blockContent${node.id} {${styleToString(node.customStyleMobile)}}
          }`
        : '';

    const hoverStyle = node.customStyle && (node.tagName === "a" || node.type === NodeType.ButtonNode)
        ? `#blockContent${node.id}:hover {${styleToStringWithAction(node.customStyle, 'hover')}}`
        : '';

    const hoverStyleMobile = node.customStyleMobile && (node.tagName === "a" || node.type === NodeType.ButtonNode)
        ? `#blockContent${node.id}:hover {${styleToStringWithAction(node.customStyleMobile, 'hover')}}`
        : '';

    const activeStyle = node.customStyle && (node.className === "btn" || node.tagName === "a" || node.tagName === 'button') && node.customStyle['active']
        ? `#blockContent${node.id}:active {background: ${(node.customStyle['active'])}!important}`
        : '';

    const activeStyleMobile = node.customStyleMobile && (node.className === "btn" || node.tagName === "a" || node.tagName === 'button') && node.customStyleMobile['active']
        ? `#blockContent${node.id}:active {background: ${(node.customStyleMobile['active'])}!important}`
        : '';

    // const desktopHiddenStyle = node.hidden === "desktopHidden" && !isMobileViewDimension && node.className === "container"
    //     ? `@media (min-width: 576px) {
    //         #blockContent${node.id} {display: none!important}
    //       }`
    //     : '';
    //
    // const mobileHiddenStyle = node.hidden === "mobileHidden" && isMobileViewDimension && node.className === "container"
    //     ? `@media (max-width: 576px) {
    //         #blockContent${node.id} {display: none!important}
    //       }`
    //     : '';

    return (
        <>
            {mobileStyle && generateStyleTag(mobileStyle)}
            {!node.isMobileStyleStates || !isMobileViewDimension ? hoverStyle && generateStyleTag(hoverStyle) : hoverStyleMobile && generateStyleTag(hoverStyleMobile)}
            {!node.isMobileStyleStates || !isMobileViewDimension ? activeStyle && generateStyleTag(activeStyle) : activeStyleMobile && generateStyleTag(activeStyleMobile)}
            {/*{desktopHiddenStyle && generateStyleTag(desktopHiddenStyle)}*/}
            {/*{mobileHiddenStyle && generateStyleTag(mobileHiddenStyle)}*/}
        </>
    );
};

export default CustomStyles;
