import React from "react";
import {PageNode} from "../types/PageNode";

export function changeStyleByFormEvent(e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>, nodeState: PageNode, isMobileStyle: boolean) {
    return changeStyleByValue(e.target.name, e.target.value, nodeState, isMobileStyle);
}

export function changeStyleByValue(name: string, value: string, node: PageNode, isMobileStyle: boolean) {
    let tmpNode = {...node};
    if (isMobileStyle) {
        if (tmpNode.customStyleMobile === undefined) {
            tmpNode.customStyleMobile = {};
        }
        tmpNode.customStyleMobile = Object.assign({...tmpNode.customStyleMobile}, {[name]: value});
    } else {
        if (tmpNode.customStyle === undefined) {
            tmpNode.customStyle = {};
        }
        tmpNode.customStyle = Object.assign({...tmpNode.customStyle}, {[name]: value});
    }
    return tmpNode;
}

export function changeAttributeOfNode(e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>, nodeState: any) {
    let tmpNode = {...nodeState};
    if (tmpNode['attributes'] === undefined) {
        tmpNode['attributes'] = {};
    }
    tmpNode['attributes'] = Object.assign({...tmpNode['attributes']}, {[e.target.name]: e.target.value});
    return tmpNode;
}

export function deleteCustomStyleByName(name: string, node: PageNode, isEditMobileVersion: boolean) {
    let tmpNode = {...node};

    if (tmpNode.customStyle !== undefined && !isEditMobileVersion) {
        tmpNode.customStyle = Object.assign(
            {...tmpNode.customStyle},
            {[name]: ""}
        );
        delete tmpNode["customStyle"][name];
    }
    if (tmpNode.customStyleMobile !== undefined && isEditMobileVersion) {
        tmpNode.customStyleMobile = Object.assign(
            {...tmpNode.customStyleMobile},
            {[name]: ""}
        );
        delete tmpNode.customStyleMobile[name];
    }
    return tmpNode;
}

export function changeBackClassesByValue(incomeName: string, incomeValue: string, nodeState: PageNode, isEditMobileVersion: boolean) {
    let tmpNode = {...nodeState};
    if (tmpNode.backClassesMobile === undefined) {
        tmpNode.backClassesMobile = {};
    }
    if (tmpNode.backClassesIndexed === undefined) {
        tmpNode.backClassesIndexed = {};
    }
    if (isEditMobileVersion) {
        let value = incomeValue.replace('{size}', '-');
        tmpNode.backClassesMobile = Object.assign({...tmpNode.backClassesMobile}, {[incomeName]: value});
    }
    if (!isEditMobileVersion) {
        let value = incomeValue.replace('{size}', '-md');
        tmpNode.backClassesIndexed = Object.assign({...tmpNode.backClassesIndexed}, {[incomeName]: value});
    }

    return tmpNode;
}

export function deleteBackClassesByName(name: string, nodeState: PageNode, isEditMobileVersion: boolean) {
    let tmpNode = {...nodeState};
    if (isEditMobileVersion && tmpNode.backClassesMobile && Object.keys(tmpNode.backClassesMobile).length) {
        tmpNode.backClassesMobile = Object.assign({...tmpNode.backClassesMobile}, {[name]: ""});
        delete tmpNode.backClassesMobile[name];
    }

    if (!isEditMobileVersion && tmpNode.backClassesIndexed && Object.keys(tmpNode.backClassesIndexed).length) {
        tmpNode.backClassesIndexed = Object.assign({...tmpNode.backClassesIndexed}, {[name]: ""});
        delete tmpNode.backClassesIndexed[name];
    }

    return tmpNode;
}


export type ChangeStyleFunction = (name: string, value: string, isMobileViewDimension: boolean) => void;
export type DeleteCustomStyleByNameFunction = (name: string, isMobileViewDimension: boolean) => void;
export type ChangeNodeFunction = (node: PageNode) => void;
export type ChangeBackClassesFunction = (name: string, value: string, isEditMobileVersion: boolean) => void;
export type DeleteBackClassesByNameFunction = (name: string, isEditMobileVersion: boolean) => void;
