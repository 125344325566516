import React, {ChangeEvent, useEffect, useState} from "react";
import {ChangeStyleFunction, DeleteCustomStyleByNameFunction} from "../../../../service/editModalService";
import {PageNode} from "../../../../types/PageNode";
import {InputTypes, OnChangeType} from "../../../../types/InputTypes";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";

export interface InputWidthPostfixProperty {
    optionValue: string,
    changeStyleFunction: ChangeStyleFunction,
    labelName?: string | null,
    editableNode: PageNode,
    formControlClass?: string | null,
    formFloatingClass?: string | null,
    formLabelClass?: string | null,
    icon?: string | null,
    isMobileStyle: boolean
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    inputType?: string|null,
    isMixedStyle?: boolean|null,
    changeCustomFunction?: (editedNode: PageNode, isMobileStyle: boolean, isMixed: boolean) => PageNode|null,
    handleChangeIsMixedStyle?: (isMixed: boolean) => void
}

export const EditModalSizeInput: React.FC<InputWidthPostfixProperty> = (props) => {
    const activeNode = useSelector((state: RootState) => state.nodeModal.value) as PageNode;
    const optionValue = props.optionValue;
    const [nodeCustomStyle, setNodeCustomStyle] = useState<CustomStyle | undefined>();
    const [propertyValue, setPropertyValue] = useState('0');
    const [type, setType] = useState(OnChangeType.Pixels);

    useEffect(() => {
        const { editableNode, optionValue, isMobileStyle } = props;
        const customStyle = isMobileStyle ? editableNode?.customStyleMobile : editableNode?.customStyle;
        const rawValue = customStyle?.[optionValue] || editableNode?.customStyle?.[optionValue];
        const formattedValue = rawValue ? rawValue.replace(/[^\d,.-]/g, '').replace(",", ".") : '0';
        setNodeCustomStyle(customStyle);
        setPropertyValue(formattedValue);
        // eslint-disable-next-line
    }, [props.editableNode, props.isMobileStyle]);

    useEffect(()=> {
        if (props.inputType === InputTypes.Mixed) {
            if (props.isMixedStyle) {
                setType(OnChangeType.Custom)
            } else {
                setType(OnChangeType.Pixels)
            }
        } else if (nodeCustomStyle && nodeCustomStyle[optionValue] && props.inputType === InputTypes.Dual) {
            if (nodeCustomStyle[optionValue].includes('%')) {
                setType(OnChangeType.Percentage)
            } else {
                setType(OnChangeType.Pixels)
            }
        }
    }, [activeNode, optionValue, props.inputType, props.isMixedStyle, nodeCustomStyle])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        setPropertyValue(e.target.value.replace(/[^\d,.-]/g, '').replace(",", "."));
        if (type === OnChangeType.Custom) {
            return
        }
        let targetValue = e.target.value.replace(",", ".");
        if (targetValue.includes('-') && !optionValue.startsWith('margin')) {
            targetValue = targetValue.replace('-', '')
        }
        if(targetValue==='')
        {
            targetValue='0';
        }
        if (targetValue.startsWith('0') && targetValue.length !== 1) {
            props.changeStyleFunction(e.target.name, targetValue.slice(1, 2) + type, props.isMobileStyle);
        } else if (targetValue === '0' && !props.isMobileStyle) {
            props.deleteStyleByName(e.target.name, props.isMobileStyle)
        } else if (type === OnChangeType.Percentage) {
            if (Number(targetValue) >= 0 && Number(targetValue) <= 100) {
                props.changeStyleFunction(e.target.name, targetValue + type, props.isMobileStyle);
            } else {
                props.changeStyleFunction(e.target.name, '100' + type, props.isMobileStyle)
            }
        } else {
            props.changeStyleFunction(e.target.name, targetValue + type, props.isMobileStyle);
        }
        let value = e.target.value.replace(/^0(?=\d)|[^\d,.-]/g, '').replace(",", ".");
        setPropertyValue(value);
    };

    const onSizeTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        props.deleteStyleByName(e.target.name, props.isMobileStyle)
        if (e.target.value === 'px') {
            setType(OnChangeType.Pixels)
        } else if (e.target.value === '%') {
            setType(OnChangeType.Percentage)
        }
    }

    const checkOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const isChecked = Boolean(e.target.checked)
        props?.handleChangeIsMixedStyle && props.handleChangeIsMixedStyle(isChecked);
        if (isChecked) {
            setType(OnChangeType.Custom)
        } else {
            setType(OnChangeType.Pixels)
        }
        props.changeCustomFunction(props.editableNode, props.isMobileStyle, isChecked)
    }

    return (
        <>
            {props.inputType === InputTypes.Dual ?
                <div className="input-group d-flex">
                    <div
                        className={`position-relative text-background-mobile form-floating ${props.formFloatingClass ?? props.formFloatingClass}`}
                        style={{flex: '10'}}
                    >
                        <input id={"edit-block-modal-style-input"}
                               onChange={(e) => handleChange(e, type)}
                               name={optionValue}
                               // type="number"
                               value={propertyValue}
                               placeholder={props.labelName}
                               className={`form-control ${props.formFloatingClass ?? props.formFloatingClass}`}/>
                        <label htmlFor="edit-block-modal-style-input"
                               className={`form-label ${props.formLabelClass ?? props.formLabelClass}`}>
                            {props.icon ? props.icon : ""} {props.labelName}</label>
                    </div>
                    <select className="form-select" id="inputSizeTypeSelect" name={optionValue} style={{flex:'1'}}
                            onChange={onSizeTypeChange} value={type}>
                        <option value="px">px</option>
                        <option value="%">%</option>
                    </select>
                </div>
                :
                props.inputType === InputTypes.Mixed ?
                    <div className="input-group d-flex">
                        <div
                            className={`position-relative text-background-mobile form-floating col-7 ${props.formFloatingClass ?? props.formFloatingClass}`}
                        >
                            <input id={"edit-block-modal-style-input"}
                                   onChange={(e) => handleChange(e, type)}
                                   name={optionValue}
                                   value={type === OnChangeType.Custom ? 'Mixed': propertyValue}
                                   placeholder={props.labelName}
                                   className={`form-control ${props.formFloatingClass ?? props.formFloatingClass}`}/>
                            <label htmlFor="edit-block-modal-style-input"
                                   className={`form-label ${props.formLabelClass ?? props.formLabelClass}`}>
                                {props.icon ? props.icon : ""} {props.labelName}</label>
                        </div>
                        <div className="align-items-center justify-content-center border-secondary border border-opacity-25 rounded-end-2 p-2 gap-2 form-switch col-5 d-flex">
                            <label className="form-check-label">Custom</label>
                            <input className="form-check-input m-0" type="checkbox"
                                   checked={props.isMixedStyle} onChange={checkOnChange}/>
                        </div>
                    </div>
                    :
                    <div
                        className={`position-relative text-background-mobile form-floating ${props.formFloatingClass ?? props.formFloatingClass}`}>
                        <p className='position-absolute end-0 top-50 translate-middle pe-lg-3 me-2 text-background-mobile'>
                            <small>px</small></p>
                        <input id={"edit-block-modal-style-input"}
                               onChange={(e) => handleChange(e, type)}
                               name={optionValue}
                               value={propertyValue}
                               placeholder={props.labelName}
                               className={`form-control ${props.formFloatingClass ?? props.formFloatingClass}`}/>
                        <label htmlFor="edit-block-modal-style-input"
                               className={`form-label ${props.formLabelClass ?? props.formLabelClass}`}>
                            {props.icon ? props.icon : ""} {props.labelName}</label>
                    </div>
            }
        </>
    )

}

export default EditModalSizeInput;