import html2canvas from "html2canvas";
import {AppDispatch} from "../../store";
import {changeScreenShotNodeId} from "../../reducers/app";
import {ServiceInstance} from "../ServiceContainer";
import YoutubeBg from "./img/youtube-bg.svg";
import YoutubeIcon from "./img/youtube-icon.svg";
import ChannelIcon from "./img/channel-icon.svg";
import DotsVerticalIcon from "./img/dots-vertical.svg";
import GeneralBg from "./img/general-bg.svg";
import GeneralIcon from "./img/general-icon.svg";
import PageService from "../Page/PageService";

export interface ScreenShotProcessor {
    onScreenShotReady(result: string): Promise<string>

    getNodeId(): string

    onScreenShotAction(): Promise<string>
}

export class ScreenShotService implements ServiceInstance {
    screenShotProcessor: ScreenShotProcessor;
    private dispatch: AppDispatch;

    public constructor(dispatch: AppDispatch) {
        this.dispatch = dispatch
    }

    private replaceIframesWithPlaceholders(newDiv: HTMLElement, isElementMainNode: boolean): void {
        const isHasParentPage = !!PageService.getInstance().page?.parentTemplate;
        const iframes = newDiv.querySelectorAll("iframe");
        iframes.forEach((iframe) => {
            const iframeComputedStyle = getComputedStyle(iframe);
            const wrapper = document.createElement('div');
            if((isElementMainNode || !isHasParentPage) && iframe.src.includes("youtube.com")) {
                wrapper.innerHTML = `
                <div style="background-image: url('${YoutubeBg}'); background-size: cover; background-position: center; background-repeat: no-repeat; position: relative; padding: 25px; width: ${iframeComputedStyle.width}; height: ${iframeComputedStyle.height};">
                    <img src='${YoutubeIcon}' style="width: 70px; position: absolute; top: 50%; right: 50%; transform: translate(50%, -50%);" />
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <div style="display: flex; align-items: center; gap: 16px;">
                            <img src='${ChannelIcon}' style="width: 39px" />
                            <div style="color: white">Video Channel</div>
                        </div>
                        <img src='${DotsVerticalIcon}' style="width: 25px" />
                    </div>
                </div>
                `;
            } else {
                wrapper.innerHTML = `
                <div style="background-image: url('${GeneralBg}'); background-size: cover; background-position: center; background-repeat: no-repeat; position: relative; padding: 22px; width: ${iframeComputedStyle.width}; height: ${iframeComputedStyle.height};">
                    <img src='${GeneralIcon}' style="width: 70px; position: absolute; top: 50%; right: 50%; transform: translate(50%, -50%);" />
                </div>
                `;
            }

            iframe.replaceWith(wrapper);
        });
    }

    runScreenShot(screenShotProcessor: ScreenShotProcessor) {
        this.screenShotProcessor = screenShotProcessor
        this.dispatch(changeScreenShotNodeId(screenShotProcessor.getNodeId()))
    }

    onScreenShotAction(): Promise<string> {
        return this.screenShotProcessor.onScreenShotAction()
    }

    onScreenShotReady(result: string): void {
        this.screenShotProcessor.onScreenShotReady(result).then(value => {
            this.dispatch(changeScreenShotNodeId(undefined))
        });
    }
    static  takeMobileScreenShot(element: HTMLElement) {
        return ScreenShotService.takeScreenshotOfElement(element, 400, 865, element.clientWidth, 865)
    }

    static takeTemplateScreenShot(element: HTMLElement) {
        return ScreenShotService.takeScreenshotOfElement(element, element.clientWidth, element.clientHeight, element.clientWidth, element.clientHeight)
    }

    static takePCScreenShot(element: HTMLElement) {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight
        let width = 400;
        let height = 400 / (windowWidth / windowHeight);
        if (window.innerWidth < window.innerHeight) {
            width = 400;
            height = 285;
        }
        return ScreenShotService.takeScreenshotOfElement(element, width, height, windowWidth, windowHeight)
    }

    static takeScreenshotOfElement = async (element: HTMLElement, endImageWidth: number, endImageHeight: number, inImageWidth: number, inImageHeight: number) => {
        const mainNode = document.querySelector("#blockContent1") as HTMLElement;
        const computedStyle = getComputedStyle(mainNode);
        const { background, backgroundImage, backgroundSize } = computedStyle;

        const newDiv = document.createElement('div');
        newDiv.style.width = `${element.offsetWidth}px`;
        newDiv.style.height = `${element.offsetHeight}px`;

        if (background) newDiv.style.background = background;
        if (backgroundImage) newDiv.style.backgroundImage = backgroundImage;
        if (backgroundSize) newDiv.style.backgroundSize = backgroundSize;
        document.body.appendChild(newDiv);
        
        const clonedElement = element.cloneNode(true) as HTMLElement;
        newDiv.appendChild(clonedElement);

        this.prototype.replaceIframesWithPlaceholders(newDiv, mainNode.id === element.id);
   
        try {
            const canvas = await html2canvas(newDiv, {
                useCORS: true,
                width: inImageWidth,
                height: inImageHeight
            });

            const newCanvas = document.createElement('canvas');
            newCanvas.width = endImageWidth;
            newCanvas.height = endImageHeight;

            const newCtx = newCanvas.getContext('2d');
            newCtx.drawImage(canvas, 0, 0, endImageWidth, endImageHeight);
            return newCanvas.toDataURL("image/jpeg", 0.6);
        } catch (error) {
            console.error("Ошибка создания скриншота: ", error);
            return null;
        } finally {
            newDiv.remove();
        }
    };
}