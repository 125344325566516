import { useSelector } from "react-redux";
import { RootState } from "../store";
import { CustomStyleField } from "../types/CustomStyleField";
import { PageNode } from "../types/PageNode";
import { ComponentArrayItem, StyleCheckKeys } from "../types/StyleCheckKeys";

function useSortedComponents<T>(
  components: ComponentArrayItem<T>[],
  order: StyleCheckKeys[]
): ComponentArrayItem<T>[] {
  const activeNode = useSelector(
    (state: RootState) => state.nodeModal.value
  ) as PageNode;

  const customStyle = activeNode?.customStyle || {};
  const backClassesIndexed = activeNode?.backClassesIndexed || {};

  const styleChecks: Record<StyleCheckKeys, boolean> = {
    [StyleCheckKeys.ContainerSize]:
      !!customStyle[CustomStyleField.Width] ||
      !!customStyle[CustomStyleField.Height],
    [StyleCheckKeys.Alignment]:
      !!backClassesIndexed["horizontalAlignment"] ||
      !!backClassesIndexed["verticalAlignment"],
    [StyleCheckKeys.CornerRadius]:
      !!customStyle[CustomStyleField.BorderRadius] ||
      !!customStyle[CustomStyleField.BorderTopRightRadius] ||
      !!customStyle[CustomStyleField.BorderTopLeftRadius] ||
      !!customStyle[CustomStyleField.BorderBottomRightRadius] ||
      !!customStyle[CustomStyleField.BorderBottomLeftRadius],
    [StyleCheckKeys.Gap]: !!customStyle[CustomStyleField.Gap],
    [StyleCheckKeys.Opacity]: !!customStyle[CustomStyleField.Opacity],
    [StyleCheckKeys.Border]:
      !!customStyle[CustomStyleField.Border] ||
      !!customStyle[CustomStyleField.BorderLeft] ||
      !!customStyle[CustomStyleField.BorderRight] ||
      !!customStyle[CustomStyleField.BorderTop] ||
      !!customStyle[CustomStyleField.BorderBottom],
    [StyleCheckKeys.Spacing]:
      !!customStyle[CustomStyleField.Margin] ||
      !!customStyle[CustomStyleField.Padding] ||
      !!customStyle[CustomStyleField.MarginBottom] ||
      !!customStyle[CustomStyleField.MarginTop] ||
      !!customStyle[CustomStyleField.MarginLeft] ||
      !!customStyle[CustomStyleField.MarginRight] ||
      !!customStyle[CustomStyleField.PaddingTop] ||
      !!customStyle[CustomStyleField.PaddingBottom] ||
      !!customStyle[CustomStyleField.PaddingLeft] ||
      !!customStyle[CustomStyleField.PaddingRight],
  };

  return components.sort((a, b) => {
    for (let type of order) {
      if (styleChecks[type]) {
        if (a.type === type) return -1;
        if (b.type === type) return 1;
      }
    }
    return 0;
  });
}

export default useSortedComponents;
