import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {PageNode} from "../../../types/PageNode";

interface DynamicElementProps extends React.HTMLAttributes<HTMLElement> {
    node: PageNode,
    children?: React.ReactNode
}

const DynamicElement: React.FC<DynamicElementProps> = ({
                                                           node,
                                                           children,
                                                           ...props
                                                       }) => {
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const previewState = useSelector((state: RootState) => state.appStorage.preview);
    const tagName= node.tagName ? node.tagName.trim() : 'div'

    if(props.id === "blockContent1") {
        const className = `${props.className ? props.className + ' ' : ''}${!previewState && nodes.length && !nodes[0].nodes.length ? "" : "min-vh-100"}`;
        props.className = className;
    }
    return React.createElement(tagName, props, children);
};

export default DynamicElement;