import React from "react";
import {ChangeStyleFunction, DeleteCustomStyleByNameFunction} from "../../../service/editModalService";
import {PageNode} from "../../../types/PageNode";

export interface InputWidthPostfixProperty {
    optionValue: string,
    changeStyleFunction: ChangeStyleFunction,
    labelName?: string | null,
    editableNode: PageNode,
    formControlClass?: string | null,
    formFloatingClass?: string | null,
    formLabelClass?: string | null,
    icon?: string | null,
    isMobileStyle: boolean
    deleteStyleByName: DeleteCustomStyleByNameFunction
}

export const Opacity: React.FC<InputWidthPostfixProperty> = (props) => {
    const optionValue = props.optionValue;
    let nodeCustomStyle: CustomStyle;
    let propertyValue: string = '100';

    if (props.isMobileStyle) {
        nodeCustomStyle = props.editableNode.customStyleMobile
        propertyValue = nodeCustomStyle && nodeCustomStyle[props.optionValue] ? nodeCustomStyle[props.optionValue].replace(/[^\d,.]/g, '') : '100'
    }
    if (propertyValue === '100') {
        nodeCustomStyle = props.editableNode.customStyle
        propertyValue = nodeCustomStyle && nodeCustomStyle[props.optionValue] ? nodeCustomStyle[props.optionValue].replace(/[^\d,.]/g, '') : '100'
    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let targetValue = e.target.value

        if (targetValue.startsWith('0') && targetValue.length !== 1) {
            props.changeStyleFunction(e.target.name, targetValue.slice(1, 2) + "%", props.isMobileStyle);
        } else if (targetValue === '') {
            props.deleteStyleByName(e.target.name, props.isMobileStyle)
            propertyValue = ''
        } else if (Number(targetValue) >= 0 && Number(targetValue) <= 100) {
            props.changeStyleFunction(e.target.name, targetValue + "%", props.isMobileStyle);
        }
    };


    return (
        <div
            className={`position-relative text-background-mobile form-floating ${props.formFloatingClass ?? props.formFloatingClass}`}>
            <p className='position-absolute end-0 top-50 translate-middle pe-3 me-2 text-background-mobile'>
                <small>%</small></p>
            <input id={"edit-block-modal-style-input"}
                   onChange={handleChange}
                   name={optionValue}
                   max={'100'}
                   min={'0'}
                   value={propertyValue}
                   placeholder={props.labelName}
                   className={`form-control ${props.formFloatingClass ?? props.formFloatingClass}`}/>
            <label htmlFor="edit-block-modal-style-input"
                   className={`form-label ${props.formLabelClass ?? props.formLabelClass}`}>
                {props.icon ? props.icon : ""} {props.labelName}</label>
        </div>
    )

}

export default Opacity;