import PageService from "../Page/PageService";
import {RequestPageType} from "../../types/RequestPageType";
import {ScreenShotService} from "./ScreenShotService";

export class PageScreenShotService {
    static takePageScreenShot(): Promise<string> {
        const isMobilePageType = PageService.getInstance().page.type === RequestPageType.MobilePage
        const element = document.getElementById('screen-shot-container');
        if (element) {
            const refElement = element.children.item(0) as HTMLElement;
            return isMobilePageType ? ScreenShotService.takeMobileScreenShot(refElement).then(value => {
                return value
            }) : ScreenShotService.takePCScreenShot(refElement).then(value => {
                return value
            });
        }
    }
}