import React from "react";
import TitleImg from "./img/title.svg";
import LogoImg from "./img/logo.svg";

const SessionExpired: React.FC = () => {
  return (
    <div className="d-flex align-items-center justify-content-start bg-main-background min-vh-100 px-3 py-4 flex-column">
      <a href={`${process.env.REACT_APP_BACKEND_ADDRESS}/login`}><img src={LogoImg} alt="Logo" className="mb-4" /></a>
      <div
        className="rounded bg-white px-3 py-5 w-100 text-center"
        style={{ maxWidth: "636px" }}
      >
        <div className="row py-5">
          <div className="col-12 col-sm-8 m-auto">
            <img src={TitleImg} alt="Session Expired" />
            <div className="text-dark-gray fs-2 fw-bold mt-4 mb-3 lh-sm">Session Expired</div>
            <div className="text-background-mobile mb-4 px-2">
              It looks like your session has expired. Please log in again to
              continue using ME-Page and regain access to your features.
            </div>
            <div className="d-flex justify-content-lg-between gap-3">
              <a
                href={`${process.env.REACT_APP_BACKEND_ADDRESS}`}
                className="btn btn-outline-primary w-100 p-3"
              >
                Main Page
              </a>
              <a
                href={`${process.env.REACT_APP_BACKEND_ADDRESS}/login`}
                className="btn btn-primary w-100 p-3 fw-bold"
              >
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SessionExpired;
