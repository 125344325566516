import {ServiceContainer} from "./ServiceContainer";
import QueryService from "./QueryService";

export async function postData(url: string = '', data = {}) {
    try {
        let headers: {}
        const token = ServiceContainer.resolve<QueryService>('QueryService').token;
        const urlPath = window.location.href;
        if(token)
        {
            headers = {'Content-Type': 'application/json', 'X-AUTH-TOKEN': token, 'URL-PATH': urlPath}
        } else {
            headers = {'Content-Type': 'application/json', 'URL-PATH': urlPath}
        }
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: headers,
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            if (response.status === 403) {
                throw new Error('403');
            } else {
                const errorMessage = `HTTP error! status: ${response.status}`;
                alert(errorMessage);
                throw new Error(errorMessage);
            }
        }

        const responseBody = await response.json();

        if (responseBody && responseBody.error) {
            throw new Error(responseBody.error);
        }

        return responseBody;
    } catch (error) {
        console.error(error);
        throw error;
    }
}




export async function loadData(url = '') {

    return await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
        .then(result => result.json())
        .then((data) => {
            return data;
        }).catch(error => alert(error))
}