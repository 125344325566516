import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeIsPublishPageState, changePreview, setInitPublishPage} from "../../reducers/app";
import {useMutation} from "@apollo/client";
import {PUBLISH_PAGE} from "../../apollo/Mutations";
import {GetPageDocument} from "../../service/Page/PageService.hooks";

function PublishPageState() {
    const pageId = useSelector((state) => state.appStorage.pageId)
    const initPublishPage = useSelector((state) => state.appStorage.initPublishPage)
    const [publishPage] = useMutation(PUBLISH_PAGE);
    const dispatch = useDispatch();
    useEffect(() => {

        const blockContent = document.getElementById("mainBlock")
        if (blockContent !== null) {
            publishPage({
                variables: {
                    content: blockContent.innerHTML.toString(),
                    pageId: parseInt(pageId)
                },
                ...(initPublishPage === false && { 
                    refetchQueries: [{ query: GetPageDocument, variables: { id: pageId } }]
                })
            }).catch((e) => {
                console.log(e)
                // alert(e);
            });
            dispatch(changePreview(false))
            dispatch(changeIsPublishPageState(false))
            dispatch(setInitPublishPage(false))
        } else {
        }
    });
}

export default PublishPageState;
