import React, { useEffect, useState } from "react";
import {
  ChangeStyleFunction,
  DeleteCustomStyleByNameFunction,
} from "../../../service/editModalService";
import EditModalSizeInput from "../ui/input/EditModalSizeInput";
import { CustomStyleField } from "../../../types/CustomStyleField";
import { PageNode } from "../../../types/PageNode";
import { InputTypes } from "../../../types/InputTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export interface CornerRadiusProps {
  deleteStyleByName: DeleteCustomStyleByNameFunction;
  changeStyleFunction: ChangeStyleFunction;
  editableNode: PageNode;
  isMobileStyleContainerSize: boolean;
  changeBorderRadiusCustomStyle?: (
    editedNode: PageNode,
    isMobileStyle: boolean,
    isMixed: boolean
  ) => PageNode | null;
}

const CornerRadius: React.FC<CornerRadiusProps> = ({
  deleteStyleByName,
  changeStyleFunction,
  editableNode,
  isMobileStyleContainerSize,
  changeBorderRadiusCustomStyle,
}) => {
  const activeNode = useSelector(
    (state: RootState) => state.nodeModal.value
  ) as PageNode;
  const [isMixedBorderRadius, setIsMixedBorderRarius] = useState(false);

  const updateMixedValues = () => {
    const isAnyRadius = !!(
      activeNode?.customStyle?.borderTopLeftRadius ||
      activeNode?.customStyle?.borderTopRightRadius ||
      activeNode?.customStyle?.borderBottomLeftRadius ||
      activeNode?.customStyle?.borderBottomRightRadius
    );
    setIsMixedBorderRarius(editableNode.isMixedBorderRadius || isAnyRadius);
    if (isMobileStyleContainerSize) {
      setIsMixedBorderRarius(activeNode.isMobileMixedBorderRadius);
    }
  };

  useEffect(() => {
    if (!activeNode) return;
    updateMixedValues();
    const modalElement = document.getElementById("edit-block-modal");
    const handleClose = () => {
      updateMixedValues();
    };

    modalElement.addEventListener("hide.bs.modal", handleClose);
    return () => {
      modalElement.removeEventListener("hide.bs.modal", handleClose);
    };
    // eslint-disable-next-line
  }, [activeNode]);

  return (
    <>
      <div className="col-md-6">
        <EditModalSizeInput
          deleteStyleByName={deleteStyleByName}
          changeStyleFunction={changeStyleFunction}
          editableNode={editableNode}
          optionValue={CustomStyleField.BorderRadius}
          labelName={"Corner Radius (px)"}
          isMobileStyle={isMobileStyleContainerSize}
          inputType={InputTypes.Mixed}
          isMixedStyle={isMixedBorderRadius}
          changeCustomFunction={changeBorderRadiusCustomStyle}
          handleChangeIsMixedStyle={setIsMixedBorderRarius}
        />
      </div>

      {isMixedBorderRadius && (
        <>
          <p className="fw-bold">Corner Radius</p>
          <div className="col-6 mb-3">
            <EditModalSizeInput
              deleteStyleByName={deleteStyleByName}
              changeStyleFunction={changeStyleFunction}
              editableNode={editableNode}
              optionValue={CustomStyleField.BorderTopLeftRadius}
              labelName={"Top Left"}
              isMobileStyle={isMobileStyleContainerSize}
            />
          </div>
          <div className="col-6 mb-3">
            <EditModalSizeInput
              deleteStyleByName={deleteStyleByName}
              changeStyleFunction={changeStyleFunction}
              editableNode={editableNode}
              optionValue={CustomStyleField.BorderTopRightRadius}
              labelName={"Top Right"}
              isMobileStyle={isMobileStyleContainerSize}
            />
          </div>
          <div className="col-6 mb-3">
            <EditModalSizeInput
              deleteStyleByName={deleteStyleByName}
              changeStyleFunction={changeStyleFunction}
              editableNode={editableNode}
              optionValue={CustomStyleField.BorderBottomLeftRadius}
              labelName={"Bottom Left"}
              isMobileStyle={isMobileStyleContainerSize}
            />
          </div>
          <div className="col-6 mb-3">
            <EditModalSizeInput
              deleteStyleByName={deleteStyleByName}
              changeStyleFunction={changeStyleFunction}
              editableNode={editableNode}
              optionValue={CustomStyleField.BorderBottomRightRadius}
              labelName={"Bottom Right"}
              isMobileStyle={isMobileStyleContainerSize}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CornerRadius;
