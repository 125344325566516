import React, {useEffect, useState} from "react";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {UserRole} from "../../../types/User";

interface MobileStyleRadioButtonProperty {
    currentStatus: boolean | undefined,
    onChange: (state: boolean) => void,
}

export const MobileStyleRadioButton: React.FC<MobileStyleRadioButtonProperty> = (props) => {
    const [isApplyOnMobileOnly, setIsApplyOnMobileOnly] = useState(props.currentStatus)
    const isMobileViewDimension = useSelector((state:RootState) => state.appStorage.isMobileViewDimension)
    useEffect(() => {
        setIsApplyOnMobileOnly(props.currentStatus)
    }, [props.currentStatus]);

    const user = useSelector((state: RootState) => state.appStorage.user)
    if (user) {
        const isAdmin = user.roles.includes(UserRole.ROLE_ADMIN)
        if (!isAdmin || !isMobileViewDimension) {
            return <></>
        }
    }

    return (
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox"
                   onChange={() => {
                       setIsApplyOnMobileOnly(!isApplyOnMobileOnly)
                       props.onChange(!isApplyOnMobileOnly)
                   }}
                   checked={isApplyOnMobileOnly}
                   id="isApplyOnMobileOnlyContainerSize"/>
        </div>
    )
}