export enum StyleCheckKeys {
    ContainerSize = 'containerSize',
    Alignment = 'alignment',
    CornerRadius = 'cornerRadius',
    Gap = 'gap',
    Opacity = 'opacity',
    Border = 'border',
    Spacing = 'spacing'
}

export interface ComponentArrayItem<T> {
    condition: boolean;
    type: StyleCheckKeys;
    component: React.FC<T>;
    props: T;
}