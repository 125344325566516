import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    addNewIdForChildNodes,
    addNodeAfterActiveNode,
    addNodeToActiveNode,
    changeDataBSTargetId,
    findTopParent
} from "../../../service/NodeService";
import {Modal} from "bootstrap";
import {RootState} from "../../../store";
import {PageNode} from "../../../types/PageNode";
import {Template} from "../../../graphql/types";
import {changeNewNode} from "../../../reducers/app";

interface IncomeProps {
    template: Template,
}

const CommonTemplate: React.FC<IncomeProps> = ({template}) => {
    const activeNode = useSelector((state:RootState) => state.nodeModal.value)
    const dispatch = useDispatch();
    const srcImage = process.env.REACT_APP_IMAGE_ADDRESS + template.img
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    return <>
        <div key={template.id} className='col-lg-3 col-6 ps-1 p-1 mb-2' data-bs-dismiss="modal"
             onClick={() => {
                let nodeToAdd:PageNode = JSON.parse(template.content);
                nodeToAdd.template = {id: template.id,type:template.type,isCommon:true}
                if (activeNode?.parentId === '1') {
                     const idArray = addNewIdForChildNodes(nodeToAdd, '1');
                     changeDataBSTargetId(nodeToAdd, idArray)
                     addNodeAfterActiveNode(dispatch, activeNode, nodeToAdd, nodes)
                } else if (activeNode?.parentId === null) {
                    const idArray = addNewIdForChildNodes(nodeToAdd);
                    changeDataBSTargetId(nodeToAdd, idArray)
                    addNodeToActiveNode(dispatch, nodes[0], nodeToAdd);
                } else {
                    const idArray = addNewIdForChildNodes(nodeToAdd);
                    changeDataBSTargetId(nodeToAdd, idArray)
                    const topParent = findTopParent(activeNode.parentId, nodes[0].nodes);
                    addNodeAfterActiveNode(dispatch, topParent, nodeToAdd, nodes)
                }
                dispatch(changeNewNode(nodeToAdd))
                Modal.getOrCreateInstance('#edit-block-modal').hide();
             }}>
            <div className="blue-hover rounded-2 h-100 d-flex align-items-start justify-content-center">
                <img className={"w-100 rounded-2"} src={srcImage} alt={'heading block'}/>
            </div>
        </div>
    </>
}


export default CommonTemplate
