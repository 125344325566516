import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import BackgroundSettings from "../../EditModal/components/BackgroundSettings";
import { PageNode } from "../../../types/PageNode";
import {
  changeStyleByValue,
  ChangeStyleFunction,
  deleteCustomStyleByName,
  DeleteCustomStyleByNameFunction,
} from "../../../service/editModalService";
import FontPickerModule from "../block/modules/fontPickerModule";
import FontSizePicker from "../block/modules/fontSizePicker";
import { CustomStyleField } from "../../../types/CustomStyleField";
import { changeNode } from "../../../reducers/nodes";
import { Modal } from "bootstrap";
import FontColorPickerModule from "../block/modules/FontColorPickerModule";
import { changeTitle } from "../../../reducers/app";
import { GetPageDocument, useUpdatePageDataMutation } from "../../../service/Page/PageService.hooks";

const GlobalSettingsModal = () => {
  const mainNode: PageNode = useSelector(
    (state: RootState) => state.nodes.present.value[0]
  );
  const isMobileViewDimension = useSelector(
    (state: RootState) => state.appStorage.isMobileViewDimension
  );
  const pageTitle = useSelector((state: RootState) => state.appStorage.title);
  const pageId = useSelector((state: RootState) => state.appStorage.pageId);
  const dispatch = useDispatch();

  const [nodeState, setNodeState] = useState({ ...mainNode });
  const [title, setTitle] = useState(pageTitle || "");

  const [updatePageData] = useUpdatePageDataMutation({
    variables: {
      id: pageId,
      title: title.trim(),
      seoTitle: title.trim(),
    },
    refetchQueries: [{ query: GetPageDocument, variables: { id: pageId }, fetchPolicy: 'network-only' }]
  });

  useEffect(() => {
    setNodeState({ ...mainNode });
  }, [mainNode]);

  const changeNodeFunction = (changedNode: PageNode) => {
    setNodeState(changedNode);
  };

  const changeFormStyleFunction = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNodeState({
      ...nodeState,
      customStyle: {
        ...nodeState.customStyle,
        [e.target.name]: e.target.value,
      },
    });
  };

  const deleteFontFunction = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNodeState({
      ...nodeState,
      customStyle: {
        ...nodeState.customStyle,
        [e.target.name]: undefined,
      },
    });
  };

  const deleteStyleByName: DeleteCustomStyleByNameFunction = (
    name,
    isMobileViewDimension
  ) => {
    setNodeState(
      deleteCustomStyleByName(name, nodeState, isMobileViewDimension)
    );
  };

  const changeStyleFunction: ChangeStyleFunction = (
    name,
    value,
    isMobileViewDimension
  ) => {
    const changedNode = changeStyleByValue(
      name,
      value,
      nodeState,
      isMobileViewDimension
    );
    setNodeState(changedNode);
  };

  const submit = async () => {
    dispatch(changeNode({ editedNode: nodeState }));
    if (title !== pageTitle) {
      updatePageData().then((data) => {
        const { title: pageNewTitle } = data.data.updatePage;
        dispatch(changeTitle(pageNewTitle));
      });
    }
    Modal.getOrCreateInstance("#global-settings-modal").hide();
  };

  return (
    <div className="modal modal-lg" id="global-settings-modal" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content text-dark-gray">
          <div className="modal-header border-0">
            <h5 className="fs-4 mb-0">Page Settings</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body pb-0">
            <div className={"container px-3"}>
              <h5 className="fs-8">General Settings</h5>
              <div className="form-floating mb-3">
                <input
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  placeholder={"Enter your text"}
                  type="text"
                  className={"form-control"}
                />
                <label
                  htmlFor="title"
                  className="form-label text-background-mobile"
                >
                  Page Name
                </label>
              </div>

              <h5 className="fs-8 mt-4">Typography</h5>
              <div className="mt-1">
                <FontPickerModule
                  nodeCustomStyle={nodeState.customStyle}
                  changeFormStyleFunction={changeFormStyleFunction}
                  styleName={CustomStyleField.fontFamily}
                  labelName={"Select Font"}
                  deleteFunction={deleteFontFunction}
                />
              </div>
              <div className="row g-3 mt-0">
                <div className="col-md-6">
                  <FontSizePicker
                    changeFormStyleFunction={changeFormStyleFunction}
                    nodeCustomStyle={nodeState.customStyle}
                    deleteFunction={deleteFontFunction}
                  />
                </div>
                <div className="col-md-6">
                  <FontColorPickerModule
                    changeNodeFunction={changeNodeFunction}
                    editableNode={nodeState}
                    labelName="Text Color"
                  />
                </div>
              </div>

              <BackgroundSettings
                changeNodeFunction={changeNodeFunction}
                deleteStyleByName={deleteStyleByName}
                isMobileViewDimension={isMobileViewDimension}
                editableNode={nodeState}
                changeStyleFunction={changeStyleFunction}
              />
            </div>
          </div>
          <div className="modal-footer bg-white border-0 justify-content-between sticky-bottom">
            <button
              type="button"
              className="col-4 btn btn-outline-dark-gray rounded-1 py-2 pe-1"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="col-4 btn btn-primary fw-bold rounded-1 py-2 text-center"
              onClick={submit}
            >
              Save <span className="d-none d-md-inline">Changes</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettingsModal;
