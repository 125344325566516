import React, {useState} from 'react'
import {
    ChangeNodeFunction,
    ChangeStyleFunction,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import {CustomStyleField} from "../../../types/CustomStyleField";
import {MobileStyleRadioButton} from "../ui/MobileStyleRadioButton";
import SelectColor from "./SelectColor";
import {ButtonNode, NodeType} from "../../../types/PageNode";

export interface ButtonSettingsProps {
    editableNode: ButtonNode,
    changeNodeFunction: ChangeNodeFunction,
    changeStyleFunction: ChangeStyleFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    isMobileViewDimension: boolean,
}

const ButtonSettings: React.FC<ButtonSettingsProps> = ({changeNodeFunction, isMobileViewDimension, changeStyleFunction, deleteStyleByName, editableNode}) => {
    const [showBlock, setShowBLock] = useState<string>("")

    let isMobileStyleColor: boolean = false
    let isMobileStyleStates: boolean = false
    if (isMobileViewDimension) {
        isMobileStyleColor = editableNode.isMobileStyleColor
        isMobileStyleStates = editableNode.isMobileStyleStates

    }

    return (
        <>
            <div className="mb-2">
                <div className="d-flex align-items-center">
                    <h5 className="mt-3 me-auto">Color</h5>
                    <div className="mt-3">
                        <MobileStyleRadioButton currentStatus={isMobileStyleColor}
                                                onChange={(state) => {
                                                    editableNode.isMobileStyleColor = state;
                                                    changeNodeFunction(editableNode)
                                                }}/>
                    </div>
                </div>

                <div className="row">
                    {(editableNode.type === NodeType.ButtonNode) &&
                        <div className="col-md-6 mt-1">
                            <SelectColor editableNode={editableNode}
                                         changeStyleFunction={changeStyleFunction}
                                         styleName={CustomStyleField.Background} labelName={'Button Color'}
                                         showBlock={showBlock}
                                         setShowBLock={setShowBLock}
                                         deleteStyleByName={deleteStyleByName} isMobileStyle={isMobileStyleColor}/>
                        </div>
                    }
                    <div className="col-md-6 mt-1">
                        <SelectColor editableNode={editableNode}
                                     changeStyleFunction={changeStyleFunction}
                                     styleName={CustomStyleField.BorderColor} labelName={'Border Color'}
                                     showBlock={showBlock}
                                     setShowBLock={setShowBLock}
                                     deleteStyleByName={deleteStyleByName} isMobileStyle={isMobileStyleColor}/>
                    </div>
                </div>
                {editableNode.type === NodeType.ButtonNode && <>
                    <div className="d-flex align-items-center">
                    <h5 className="mt-3 me-auto">States</h5>
                    <div className="mt-3">
                        <MobileStyleRadioButton currentStatus={isMobileStyleStates}
                                                onChange={(state) => {
                                                    editableNode.isMobileStyleStates = state;
                                                    changeNodeFunction(editableNode)
                                                }}/>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-1">
                            <SelectColor editableNode={editableNode}
                                         changeStyleFunction={changeStyleFunction}
                                         styleName={CustomStyleField.Active} labelName={'Active State'}
                                         showBlock={showBlock}
                                         setShowBLock={setShowBLock}
                                         deleteStyleByName={deleteStyleByName} isMobileStyle={isMobileStyleStates}/>
                        </div>
                        <div className="col-md-6 mt-1">
                            <SelectColor editableNode={editableNode}
                                         changeStyleFunction={changeStyleFunction}
                                         styleName={CustomStyleField.Hover} labelName={'Hover State'}
                                         showBlock={showBlock}
                                         setShowBLock={setShowBLock}
                                         deleteStyleByName={deleteStyleByName} isMobileStyle={isMobileStyleStates}/>
                        </div>
                        {(editableNode.type === NodeType.ButtonNode || editableNode.tagName === 'a') &&
                        <div className="col-md-6 mt-md-3 mt-1 mt-1">
                            <SelectColor editableNode={editableNode}
                                         changeStyleFunction={changeStyleFunction}
                                         styleName={CustomStyleField.HoverColor} labelName={'Hover Font Color'}
                                         showBlock={showBlock}
                                         setShowBLock={setShowBLock}
                                         deleteStyleByName={deleteStyleByName} isMobileStyle={isMobileStyleColor}/>
                        </div>
                        }
                    </div>
                </>
                }

            </div>
        </>
    )
}

export default ButtonSettings