import React from 'react'
import {MobileStyleRadioButton} from "../ui/MobileStyleRadioButton";
import {
    ChangeNodeFunction,
    ChangeStyleFunction,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import EditModalSizeInput from "../ui/input/EditModalSizeInput";
import {CustomStyleField} from "../../../types/CustomStyleField";
import {NodeType, PageNode} from "../../../types/PageNode";
import {InputTypes} from "../../../types/InputTypes";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
export interface ContainerSizeProperty {
    isMobileStyleContainerSize: boolean,
    editableNode: PageNode,
    changeStyleFunction: ChangeStyleFunction,
    changeNodeFunction: ChangeNodeFunction,
    deleteStyleByName: DeleteCustomStyleByNameFunction,
    imageSrc: string,
}


export const ContainerSize: React.FC<ContainerSizeProperty> = (props) => {
    const isMobileStyleContainerSize = props.isMobileStyleContainerSize ? props.isMobileStyleContainerSize : false;
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode);

    return (
        <div>
            {props.editableNode.type === NodeType.IconNode ? (
                (isAdvancedMode || props.imageSrc) && (
                    <div className="row gx-3">
                        <div className="col-md-6 mb-lg-0 mb-3">
                            <EditModalSizeInput
                                deleteStyleByName={props.deleteStyleByName}
                                changeStyleFunction={props.changeStyleFunction}
                                editableNode={props.editableNode}
                                optionValue={CustomStyleField.Width}
                                labelName={"Width"}
                                isMobileStyle={isMobileStyleContainerSize}
                                inputType={InputTypes.Dual}
                            />
                        </div>
                        <div className="col-md-6">
                            <EditModalSizeInput
                                deleteStyleByName={props.deleteStyleByName}
                                changeStyleFunction={props.changeStyleFunction}
                                editableNode={props.editableNode}
                                optionValue={CustomStyleField.Height}
                                labelName={"Height"}
                                isMobileStyle={isMobileStyleContainerSize}
                                inputType={InputTypes.Dual}
                            />
                        </div>
                    </div>
                )
            ) : (
                <div className="row gx-3">
                    <div className="col-md-6 mb-lg-0 mb-3">
                        {props.editableNode.type !== NodeType.DivColNode &&
                        <EditModalSizeInput
                            deleteStyleByName={props.deleteStyleByName}
                            changeStyleFunction={props.changeStyleFunction}
                            editableNode={props.editableNode}
                            optionValue={CustomStyleField.Width}
                            labelName={"Width"}
                            isMobileStyle={isMobileStyleContainerSize}
                            inputType={InputTypes.Dual}

                        />
                        }
                    </div>
                    <div className="col-md-6">
                        <EditModalSizeInput
                            deleteStyleByName={props.deleteStyleByName}
                            changeStyleFunction={props.changeStyleFunction}
                            editableNode={props.editableNode}
                            optionValue={CustomStyleField.Height}
                            labelName={"Height"}
                            isMobileStyle={isMobileStyleContainerSize}
                            inputType={InputTypes.Dual}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContainerSize;
