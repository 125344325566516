import React, {useRef, useState} from 'react';
import {changeStyleByValue} from "../../../../service/editModalService";
import {useDispatch, useSelector} from "react-redux";
import {changeNode} from "../../../../reducers/nodes";
import {RootState} from "../../../../store";
import {changeActiveModalNode} from "../../../../reducers/node-modal";
import {PageNode} from "../../../../types/PageNode";

interface InputProperty {
    getActiveTag: HTMLElement,
    activeNode: PageNode,
    onChange?: (height: number, changedNode: PageNode) => void
    onFinish?: (node: PageNode) => void
}

export const DragCorner: React.FC<InputProperty> = ({getActiveTag, activeNode, onChange}) => {
    let editableNode = {...activeNode}
    const isMobileViewDimension = useSelector((state: RootState) => state.appStorage.isMobileViewDimension);
    const sizeRef = useRef(getActiveTag ? getActiveTag.clientHeight : 0);
    const dispatch = useDispatch();
    let customStyleObject: CustomStyle
    if (activeNode.isMobileStyleContainerSize && isMobileViewDimension) {
        customStyleObject = editableNode.customStyleMobile && editableNode.customStyleMobile;
    } else {
        customStyleObject = editableNode.customStyle && editableNode.customStyle;
    }
    const [currentActiveNodeHeight, setCurrentActiveNodeHeight] = useState(customStyleObject && customStyleObject['height'] ? parseInt(customStyleObject['height']) : sizeRef.current)
    //
    const onMouseDown = (mouseEvent: React.MouseEvent<HTMLButtonElement>) => {
        const startSize = sizeRef.current;
        const startPosition = {y: mouseEvent.clientY};
        const onMouseMove = (mouseMoveEvent: MouseEvent) => {
            if (mouseMoveEvent.buttons === 0) {
                onMouseUp()
                return;
            }

            const height = startSize - startPosition.y + mouseMoveEvent.clientY;
            // currentActiveNodeHeight = height;

            editableNode = changeStyleByValue(
                'height',
                height + 'px',
                editableNode,
                activeNode.isMobileStyleContainerSize
            );
            setCurrentActiveNodeHeight(height)
            getActiveTag.style.setProperty('height', height + 'px', 'important')
            if (onChange)
                onChange(height, editableNode)
            // console.log(editableNode.customStyleMobile['height'])
            // dispatch(changeNodeNoSendRequest({activeNode, editedNode: editableNode}));
            // dispatch(changeActiveModalNode(editableNode));
        };

        const onMouseUp = () => {
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('mouseup', onMouseUp);
            // nodeToSave &&
            dispatch(changeActiveModalNode(editableNode));
            dispatch(changeNode({activeNode, editedNode: editableNode}));
        };
        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, {once: true});

    };

    return (
        <div className="d-flex align-items-center border-primary border ms-2 rounded bg-white">
            <button className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0" type="button"
                    onMouseDown={onMouseDown}><i
                className="bi bi-arrows-expand"></i> {currentActiveNodeHeight}</button>
        </div>
    );
}

export default DragCorner