import {combineReducers, configureStore} from "@reduxjs/toolkit";
import undoable, { excludeAction } from "redux-undo";
import NodesReducer, { nodesMiddleware } from "../reducers/nodes";
import NodeModalReducer from "../reducers/node-modal";
import ActiveModalReducer from "../reducers/active-modal";
import AppStorageReducer from "../reducers/app";
import ActiveContainerNodeReducer from "../reducers/active-container-node";
import ModalReducer from "../reducers/modalSlice"
import ShoppingModalReducer from "../reducers/shopping-modal"
import TabReducer from "../reducers/active-tab"


// const persistConfig = {
//     key: 'root',
//     storage,
// }
const rootReducer = combineReducers({
    nodes: undoable(NodesReducer, {
        limit: 10,
        filter: excludeAction('nodes/changeNodeNoSendRequest')
    }),
    modal: ModalReducer,
    nodeModal: NodeModalReducer,
    activeModal: ActiveModalReducer,
    tab: TabReducer,
    activeContainerNode: ActiveContainerNodeReducer,
    appStorage: AppStorageReducer,
    shoppingModal: ShoppingModalReducer
});


// const persistedReducer = persistReducer(persistConfig, rootReducer);

// @ts-ignore
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(nodesMiddleware),
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         serializableCheck: {
    //             ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    //         },
    //     }),
});
// export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
