import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {changeNode, findNode} from "../../../reducers/nodes";
import {Modal} from "bootstrap";
import {useDispatch, useSelector} from "react-redux";
import TextInput from "./modules/textInput";
import Iframe from "./modules/iframe";
import Lists from "./modules/lists";
import ImageUpload from "./modules/image-upload/image-upload";
import 'bootstrap-icons/font/bootstrap-icons.css';
import ElementsNav from "../../EditModal/components/nav/elements-nav";
import {changeActiveModalNode} from "../../../reducers/node-modal";
import Structure from "./modules/structure";
import {makeStructureChange} from "../../../service/NodeService";
import {
    changeBackClassesByValue,
    ChangeBackClassesFunction,
    changeStyleByFormEvent,
    changeStyleByValue,
    ChangeStyleFunction,
    deleteBackClassesByName,
    DeleteBackClassesByNameFunction,
    deleteCustomStyleByName,
    DeleteCustomStyleByNameFunction
} from "../../../service/editModalService";
import BackgroundSettings, { BackgroundSettingsProps } from "../../EditModal/components/BackgroundSettings";
import AdditionalSettings from "../../EditModal/components/AdditionalSettings";
import Link from "../../EditModal/components/Link";
import AttributeInputs from "./modules/custom-element/AttributeInputs";
import ShowNodeChilds from "./modules/showChilds";
import IconsCreate from "../../EditModal/components/IconsCreate";
import AdminSettingsComponent from "../../EditModal/components/Admin/AdminSettingsComponent";
import {selfClosingTagsArray} from "../../node/node";
import StyleSettings, { StyleSettingsProps } from "../../EditModal/components/StyleSettings";
import ContainerColSize from "../../EditModal/components/СontainerColSize";
import TinyTextInput from "../../EditModal/components/TinyTextInput";
import UrlSettings, { UrlSettingsProps } from "../../EditModal/components/UrlSettings";
import ButtonSettings, { ButtonSettingsProps } from "../../EditModal/components/ButtonSettings";
import IconSettings, { IconsSettingsProps } from "../../EditModal/components/IconSettings";
import {RootState} from "../../../store";
import {ButtonNode, NodeType, PageNode} from "../../../types/PageNode";

import {UserRole} from "../../../types/User";
import {ServiceContainer} from "../../../service/ServiceContainer";
import QueryService from "../../../service/QueryService";
import {changeIsPublishPageState, changePreview} from "../../../reducers/app";
import ActionSettingsComponent from "./action/ActionSettingsComponent";
import { CustomStyleField } from "../../../types/CustomStyleField";
import { OnClickActionType } from "../../../types/OnClickAction";
import { useBindFileToPageMutation, useDeleteFileMutation, useUploadFileMutation } from "../../FileUpload/FileUploadComponent.hooks";
import { ActiveTab, setActiveTab } from "../../../reducers/active-tab";
import { closeModal } from "../../../reducers/modalSlice";
import ApolloNetworkErrors from "../../apolloNetworkErrors";

interface EditBlockModalProperty {
    isAdvancedMode: boolean,
}

const EditBlockModal: React.FC<EditBlockModalProperty> = ({isAdvancedMode}) => {
    const isMobileViewDimension = useSelector((state: RootState) => state.appStorage.isMobileViewDimension)
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const activeNode = useSelector((state: RootState) => state.nodeModal.value);
    const pageId = useSelector((state: RootState) => state.appStorage.pageId);
    let [nodeState, setNodeState] = useState({...activeNode});
    const user = useSelector((state: RootState) => state.appStorage.user);
    const isAdmin = user ? user.roles.includes(UserRole.ROLE_ADMIN) : false
    const [isVcardError, setisVcardError] = useState(false);
    const [uploadFileMutation, {loading: uploadFileLoading, error: uploadFileError}] = useUploadFileMutation({onError: () => {
        setisVcardError(true)
        setTimeout(() => {
            setisVcardError(false)
        }, 4000)
    }});
    const [deleteFileMutation, {loading: deleteFileLoading}] = useDeleteFileMutation({});
    const [bindFileToPageMutation, {loading: bindFileToPageLoading}] = useBindFileToPageMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeNode && activeNode.id !== '1') {
            setNodeState({...activeNode});
        }
        if(activeNode === null){
            const modalElement = document.getElementById('edit-block-modal');
            const modal = Modal.getInstance(modalElement);
            if(modal) modal.hide()
        }
    }, [activeNode])
    let editableNode = {...nodeState};

    if (editableNode.backClassesIndexed === undefined) {
        editableNode.backClassesIndexed = {}
    }
    if (editableNode.backClassesMobile === undefined) {
        editableNode.backClassesMobile = {}
    }

    /**
     *
     * @param changedNode PageNode
     */
    const changeNodeFunction = (changedNode: PageNode) => {
        console.log(changeNodeFunction, changedNode)
        setNodeState(changedNode);
    }
    const changeFormFunction = (e: any) => {
        let tmpNode = {...editableNode};
        tmpNode[e.target.name] = e.target.value;
        setNodeState(tmpNode);
    }

    const changeFormStyleFunction = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            setNodeState(
                changeStyleByFormEvent(
                    e,
                    nodeState,
                    isMobileViewDimension,
                )
            );
        // }
    }

    /**
     *
     * @param name string
     * @param value string
     * @param isMobileViewDimension boolean
     */
    const changeStyleFunction: ChangeStyleFunction = (name, value, isMobileViewDimension) => {
        //TODO: make something with slider case
        let changedNode = changeStyleByValue(
            name, value, editableNode, isMobileViewDimension
        )
        console.log('changeStyleFunction',value, editableNode)
        setNodeState(
            changedNode
        );
    }


    /**
     *
     * @param name string
     * @param value string
     * @param isEditMobileVersion boolean
     */
    const changeBackClassesByValueFunction: ChangeBackClassesFunction = (name, value, isEditMobileVersion) => {
        setNodeState(changeBackClassesByValue(name, value, editableNode, isEditMobileVersion));
    }

    /**
     *
     * @param name string
     * @param isEditMobileVersion boolean
     */

    const deleteBackClassesFunction: DeleteBackClassesByNameFunction = (name, isEditMobileVersion) => {
        setNodeState(deleteBackClassesByName(name, editableNode, isEditMobileVersion))
    }


    const deleteStyleByName: DeleteCustomStyleByNameFunction = (name, isMobileViewDimension) => {
        setNodeState(deleteCustomStyleByName(name, editableNode, isMobileViewDimension));
    }
    let imageSrc = editableNode.src
    useEffect(() => {
        const modal = document.querySelector('#edit-block-modal');
        Object.values(ActiveTab).forEach(tab => {
            (modal.querySelector(`#nav-${tab}-tab`) as HTMLAnchorElement)?.classList.remove('active');
            (modal.querySelector(`#nav-${tab}`) as HTMLAnchorElement)?.classList.remove('active');
        });

        let activeTab = ActiveTab.CONTENT;
        const currentNode = activeNode as PageNode;
        
        if (currentNode?.tagName === 'div') {
            activeTab = ActiveTab.STYLE;
        }if(currentNode?.className === 'row'){
            activeTab = ActiveTab.CONTENT;
        }

        (modal.querySelector(`#nav-${activeTab}-tab`) as HTMLAnchorElement)?.click();
        dispatch(setActiveTab(activeTab))
        // eslint-disable-next-line
    }, [activeNode, isAdvancedMode]);

    async function submit() {
        console.log(nodeState)
        console.log(editableNode)
        let nodeToSave = nodeState;
        if (nodeToSave.attributes) {
            const newObjectAttributes: any = {}
            Object.keys(nodeToSave.attributes).forEach(key => {
                if (!newObjectAttributes[key.trim()]) {
                    newObjectAttributes[key.trim()] = nodeToSave.attributes[key]
                }
            })
            nodeToSave.attributes = newObjectAttributes
        }
        if (activeNode.className === 'row') {
            nodeToSave = makeStructureChange(activeNode, nodeState);
        }
        // if (nodeToSave.attributes && nodeToSave.attributes['shopping-cart']==='button') {
        //     updateProductInCookie({productName: nodeToSave.productName, price: nodeToSave.productPrice, productSrc: nodeToSave.src})
        // }
        if (nodeToSave?.vcardFileId) {
            try {
                await deleteFileMutation({variables: {id: nodeToSave.vcardFileId}})
            } catch (error) {}
            delete nodeToSave.vcardFileId;
        }
        if (nodeToSave?.onClickAction?.type === OnClickActionType.SaveVcard) { 
            try {
                const { data } = await uploadFileMutation({
                    variables: { file: nodeToSave?.onClickAction.base64Vcard, fileName: `vcard.vcf` }
                });
                if (data?.uploadFile?.id) {
                    await bindFileToPageMutation({ variables: { pageId: pageId, fileId: data.uploadFile.id } });
                }
                nodeToSave.onClickAction.value = process.env.REACT_APP_IMAGE_ADDRESS + data.uploadFile.fullFilePath;
                nodeToSave.vcardFileId = data.uploadFile.id;
                delete nodeToSave?.onClickAction.base64Vcard;
            } catch (error) {
                console.error("Error uploading file", error);
                return;
            }
        } else if (nodeToSave?.onClickAction?.type === OnClickActionType.None) { 
            delete nodeToSave.onClickAction;
        }
        
        dispatch(changeNode({activeNode, editedNode: nodeToSave}));
        dispatch(changeActiveModalNode(null));
        Modal.getOrCreateInstance('#edit-block-modal').hide();
        if(ServiceContainer.resolve<QueryService>('QueryService').token)
        {
            dispatch(changePreview(true))
            dispatch(changeIsPublishPageState(true))
        }
        dispatch(closeModal())
    }

    let parentNode = null;
    if (activeNode) {
        parentNode = findNode(nodes[0], activeNode.parentId);
    }

    const componentsArray = [
      {
        condition: activeNode?.tagName === "a",
        component: UrlSettings,
        props: {
          editableNode,
          changeFormStyleFunction,
          changeNodeFunction,
          deleteStyleByName,
          changeStyleFunction,
          isMobileViewDimension,
          imageSrc,
        } as UrlSettingsProps,
      },
      {
        condition:
          editableNode?.type !== NodeType.ButtonNode &&
          activeNode?.tagName !== "a",
        component: StyleSettings,
        props: {
          isMobileViewDimension,
          deleteStyleByName,
          changeNodeFunction,
          changeStyleFunction,
          editableNode,
          imageSrc,
        } as StyleSettingsProps,
      },
      {
        condition:
          editableNode?.type !== NodeType.ButtonNode &&
          activeNode?.tagName !== "a" &&
            editableNode?.type === NodeType.IconNode && !editableNode.src,
        component: IconSettings,
        props: {
          isMobileViewDimension,
          deleteStyleByName,
          changeStyleFunction,
          changeNodeFunction,
          editableNode,
        } as IconsSettingsProps,
      },
      {
        condition:
          editableNode?.type !== NodeType.ButtonNode &&
          activeNode?.tagName !== "a" &&
          activeNode?.tagName !== "img" &&
          activeNode?.type !== NodeType.ButtonNode,
        component: BackgroundSettings,
        props: {
          changeNodeFunction,
          changeStyleFunction,
          deleteStyleByName,
          isMobileViewDimension,
          editableNode,
        } as BackgroundSettingsProps,
      },
      {
        condition: !selfClosingTagsArray.includes(editableNode?.tagName),
        component: ButtonSettings,
        props: {
          changeStyleFunction,
          deleteStyleByName,
          editableNode,
          changeNodeFunction,
          isMobileViewDimension,
        } as ButtonSettingsProps,
      },
    ];

    componentsArray.sort((a, b) => {
      if (
        (activeNode?.src ||
          activeNode?.srcMobile ||
          activeNode?.customStyle?.[CustomStyleField.BackgroundColor] ||
          activeNode?.customStyleMobile?.[CustomStyleField.BackgroundColor]) && 
          editableNode?.type !== NodeType.IconNode &&
        a.component === BackgroundSettings
      ) {
        return -1;
      }
      if (
        !selfClosingTagsArray.includes(activeNode?.tagName) &&
        ((activeNode?.customStyle?.[CustomStyleField.Background] &&
          activeNode.type === NodeType.ButtonNode) ||
          activeNode?.customStyle?.[CustomStyleField.HoverColor] ||
          activeNode?.customStyle?.[CustomStyleField.BorderColor] ||
          activeNode?.customStyle?.[CustomStyleField.Active] ||
          activeNode?.customStyle?.[CustomStyleField.Hover]) &&
        a.component === ButtonSettings
      ) {
        return -1;
      }
      if (
        editableNode?.type === NodeType.IconNode &&
        a.component === IconSettings
      ) {
        return -1;
      }

      return 0;
    });

    const closeEditModal = (e: BaseSyntheticEvent) => {
        e.stopPropagation()
        dispatch(closeModal())
    }

    const closeEditModalByBackdrop = (e: BaseSyntheticEvent) => {
        if(e.target.classList.contains('modal')){
            e.stopPropagation()
            dispatch(closeModal())
        }
    }

    return <div onClick={closeEditModalByBackdrop} className="modal show modal-lg" id="edit-block-modal" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header border-0">
                    <h5 className="modal-title">Settings</h5>
                    <button onClick={closeEditModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body px-4">
                    {(activeNode && activeNode.id !== '1') &&
                        <>
                            <ElementsNav isModal={undefined}/>
                            {/*{(activeNode.className === "container") &&*/}
                            {/*    <HiddenBlock changeFormFunction={changeFormFunction}*/}
                            {/*                 editedNode={editableNode}*/}
                            {/*                 isMobileViewDimension={isMobileViewDimension}*/}
                            {/*    />*/}
                            {/*}*/}
                            <div className="mb-3">
                                <nav>
                                    <ul className="nav flex-nowrap border-bottom border-1 mb-4 overflow-x-scroll hide-scrollbar d-flex  justify-content-between "
                                        id="nav-tab" role="tablist">
                                        {((activeNode?.className !== "container-fluid" && activeNode?.type !== NodeType.DivNode && activeNode?.type !== NodeType.DivContainer) || isAdvancedMode) && (
                                            <li className='nav-item w-100'>
                                                <button className="nav-link active rounded-0 fw-bold w-100"
                                                        id="nav-content-tab" data-bs-toggle="tab"
                                                        data-bs-target="#nav-content" type="button" role="tab"
                                                        aria-controls="nav-content"
                                                        aria-selected="true"><b>Content</b>
                                                </button>
                                            </li>
                                        )}
                                        <li className='nav-item w-100'>
                                            <button className="nav-link rounded-0 fw-bold w-100" id="nav-style-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-style" type="button" role="tab"
                                                    aria-controls="nav-style"
                                                    aria-selected="false"><b>Styles</b>
                                            </button>
                                        </li>
                                        {isAdmin && <li className='nav-item w-100'>
                                            <button className="nav-link rounded-0 fw-bold w-100" id="nav-admin-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-admin" type="button" role="tab"
                                                    aria-controls="nav-admin"
                                                    aria-selected="false"><b>Admin Settings</b>
                                            </button>
                                        </li>}
                                        <li className='nav-item w-100'>
                                            <button className="nav-link rounded-0 fw-bold w-100" id="nav-action-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-action" type="button" role="tab"
                                                    aria-controls="nav-action"
                                                    aria-selected="false"><b>Action</b>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-content" role="tabpanel"
                                     aria-labelledby="nav-content-tab" tabIndex={0}>
                                    {(activeNode.tagName === 'div' && activeNode.className === 'row') &&
                                        <Structure
                                            nodeState={nodeState}
                                                   changeFormFunction={changeFormFunction}/>
                                    }
                                    {(activeNode.type === NodeType.IFrameNode) &&
                                        <Iframe changeFunction={changeNodeFunction} editableNode={editableNode}/>
                                    }
                                    {(parentNode && parentNode.className === 'row') &&
                                        <ContainerColSize editableNode={editableNode}
                                                          changeNodeFunction={changeNodeFunction}/>
                                    }
                                    {(editableNode.tagName === 'img') &&
                                        <ImageUpload
                                            editableNode={editableNode}
                                                     isMobileViewDimension={isMobileViewDimension}
                                                     changeFormFunction={changeFormFunction}
                                                     changeBackClassesByValue={changeBackClassesByValueFunction}
                                                     deleteBackClassesFunction={deleteBackClassesFunction}
                                                     changeNodeFunction={changeNodeFunction} changeStyleFunction={changeStyleFunction}
                                                     deleteStyleByName={deleteStyleByName}/>
                                    }
                                    {(activeNode.type === NodeType.IconNode) &&
                                        <>
                                            <IconsCreate editableNode={editableNode}
                                                         changeNodeFunction={changeNodeFunction}
                                                         deleteStyleByName={deleteStyleByName}
                                                         isMobileViewDimension={isMobileViewDimension}
                                                         changeStyleFunction={changeStyleFunction}/>

                                        </>

                                    }
                                    <div className={activeNode.tagName === 'text' ? 'd-block' : 'd-none'}>
                                        <TinyTextInput
                                            editableNode={editableNode}
                                            changeNodeFunction={changeNodeFunction}
                                            isMobileViewDimension={isMobileViewDimension}/>
                                    </div>
                                    {(activeNode && activeNode.nodes && activeNode.nodes.length) ?
                                        <ShowNodeChilds
                                            activeNode={activeNode}
                                            checkIsAdvancedMode={isAdvancedMode}
                                        />: <></>
                                    }
                                    {(activeNode.tagName.split('').length === 2 && activeNode.tagName.startsWith('h')) &&
                                        <TextInput editedNode={editableNode} changeFormFunction={changeFormFunction}/>
                                    }

                                    {(activeNode.tagName === 'a' || activeNode.type === NodeType.ButtonNode) &&
                                        <Link changeNodeFunction={changeNodeFunction}
                                              editableNode={editableNode as ButtonNode}
                                              changeFormFunction={changeFormFunction}
                                              isMobileViewDimension={isMobileViewDimension}
                                        />
                                    }
                                    {/*{editableNode.buttonType && editableNode.buttonType === "cart" &&*/}
                                    {/*    <>*/}
                                    {/*        <ShoppingCartSettings editedNode={editableNode} isMobileViewDimension={isMobileViewDimension}*/}
                                    {/*                              changeFunction={changeFunction} deleteStyleElementFunction={deleteStyleElementFunction}/>*/}
                                    {/*    </>*/}
                                    {/*}*/}
                                    {(activeNode.tagName === 'li') &&
                                        <Lists changeFunction={changeNodeFunction} editedNode={nodeState}/>
                                    }

                                    {/*{(activeNode.tagName === 'div' && activeNode.className === 'youtube') &&*/}
                                    {/*    <Youtube changeFunction={changeNodeFunction} editedNode={nodeState}/>*/}
                                    {/*}*/}
                                </div>
                                <div className="tab-pane fade" id="nav-style" role="tabpanel"
                                     aria-labelledby="nav-style-tab" tabIndex={0}>
                                        {componentsArray.map(
                                            ({ condition, component: Component, props }, index) => {
                                                if (!condition) return null;
                                                return <Component key={index} {...props} />;
                                            }
                                        )}
                                    {isAdvancedMode ?
                                        <>
                                            <AdditionalSettings changeNodeFunction={changeNodeFunction} editableNode={editableNode}/>
                                            <AttributeInputs setNodeState={setNodeState}
                                                             nodeState={nodeState}></AttributeInputs>
                                        </>
                                        :<></>
                                    }
                                </div>
                                {isAdmin && <div className="tab-pane fade" id="nav-admin" role="tabpanel"
                                                 aria-labelledby="nav-admin-tab" tabIndex={0}>
                                    <AdminSettingsComponent editableNode={editableNode} changeNodeFunction={changeNodeFunction}/>
                                </div>
                                }
                                <div className="tab-pane fade" id="nav-action" role="tabpanel"
                                     aria-labelledby="nav-action-tab" tabIndex={0}>
                                    <ActionSettingsComponent editableNode={editableNode} changeFunction={changeNodeFunction}/>
                                </div>
                            </div>
                        </>
                    }

                </div>
                <div className="modal-footer bg-white border-0 sticky-bottom">
                    {isVcardError && !uploadFileLoading && <div className="w-100 bg-danger bg-opacity-10 text-danger p-3 rounded">
                        Not saved: <ApolloNetworkErrors networkError={uploadFileError?.networkError} />
                    </div>}
                    <div className="row justify-content-between w-100">
                        <button onClick={closeEditModal} type="button" className="col-4 btn btn-outline-dark-gray rounded-1 py-2 pe-1"
                                data-bs-dismiss="modal">Close
                        </button>
                        <button type="button" className="col-4 btn btn-primary fw-bold rounded-1 py-2 text-center" onClick={submit} disabled={uploadFileLoading || deleteFileLoading || bindFileToPageLoading}>
                            Save <span className="d-none d-md-inline">Changes</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default EditBlockModal
