import React from 'react'
import {ImageNode} from "../../../types/PageNode";
import { customIconsSVG } from '../../EditModal/components/custom-icons';

interface ImageBlockProps {
    styleObject: CustomStyle,
    nodeToShow: ImageNode,
    classString: string,
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    handleDoubleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isMobileViewDimension: boolean,
    nodeAttributes: { [p: string]: any }
}

const IconBlock: React.FC<ImageBlockProps> = ({
                                                  styleObject,
                                                  nodeToShow,
                                                  classString,
                                                  handleClick,
                                                  handleDoubleClick,
                                                  nodeAttributes
                                              }) => {

    const isCustomIcon = nodeToShow.selectedIcon && nodeToShow.selectedIcon.split('-')[0] === "custom"
    const iconClasses = isCustomIcon ? `d-flex ${nodeToShow.selectedIcon} ${!styleObject.fontSize && "custom-icon-responsive"}` : `bi-${nodeToShow.selectedIcon ? nodeToShow.selectedIcon : 'circle'}`

    if(nodeToShow.selectedIcon){
        if(!nodeToShow.customStyle?.fontSize){
            nodeToShow.customStyle = {...nodeToShow.customStyle, fontSize: nodeToShow.customStyle?.width}
        }
        styleObject.fontSize = nodeToShow.customStyle?.fontSize
        styleObject.width = nodeToShow.customStyle?.fontSize
        styleObject.height = nodeToShow.customStyle?.fontSize
    }

    const iconBlock = document.querySelector(`#blockContent${nodeToShow.id}`)
    const svg = iconBlock?.firstChild?.firstChild as HTMLElement
    if(svg){
        svg.style.width = styleObject.fontSize
    }

    return (<>
        {!nodeToShow.selectedIcon && nodeToShow.src ?
            <img
                id={'blockContent' + nodeToShow.id}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                src={nodeToShow.src}
                alt={nodeToShow.alt !== undefined ? nodeToShow.alt : ''}
                className={classString}
                style={styleObject}
            />
            :
            <>
            {!isCustomIcon && <i {...nodeAttributes}
               onClick={handleClick}
               onDoubleClick={handleDoubleClick}
               className={`${classString} ${iconClasses} d-flex`}
               style={{...styleObject}}
               id={'blockContent' + nodeToShow.id}
            ></i>}
            {isCustomIcon && <div {...nodeAttributes}
               onClick={handleClick}
               onDoubleClick={handleDoubleClick}
               className={`${classString} ${iconClasses}`}
               style={{...styleObject, width: isCustomIcon && styleObject.width ? styleObject.width : "fit-content", height: isCustomIcon && styleObject.height ? styleObject.height : ""}}
               id={'blockContent' + nodeToShow.id}
            >
                <div
                style={{display: "flex"}}
                dangerouslySetInnerHTML={{ __html: isCustomIcon ? customIconsSVG[nodeToShow.selectedIcon] : "" }}
                >
                </div>
            </div>}
            </>
        }


    </>)
}

export default IconBlock
