import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from "../../../../store";
import {changeStyleByValue} from "../../../../service/editModalService";
import {changeActiveModalNode} from "../../../../reducers/node-modal";
import {changeNode} from "../../../../reducers/nodes";
import {PageNode} from "../../../../types/PageNode";

interface DragCornerMobileProperty {
    getActiveTag: HTMLElement,
    activeNode: PageNode,
    onChange?: (height: number,changedNode:PageNode) => void
}

export const DragCornerMobile: React.FC<DragCornerMobileProperty> = ({getActiveTag, activeNode, onChange}) => {
    let editableNode = {...activeNode}
    const isMobileViewDimension = useSelector((state: RootState) => state.appStorage.isMobileViewDimension);
    const sizeRef = useRef(getActiveTag ? getActiveTag.clientHeight : 0);
    const dispatch = useDispatch();
    let customStyleObject: CustomStyle
    if (activeNode.isMobileStyleContainerSize && isMobileViewDimension) {
        customStyleObject = editableNode.customStyleMobile && editableNode.customStyleMobile;
    } else {
        customStyleObject = editableNode.customStyle && editableNode.customStyle;
    }
    const [currentActiveNodeHeight, setCurrentActiveNodeHeight] = useState(customStyleObject && customStyleObject['height'] ? parseInt(customStyleObject['height']) : sizeRef.current)
    //
    const handleTouchStart = (touchStartEvent: React.TouchEvent<HTMLButtonElement>) => {
        const startSize = sizeRef.current;
        const startPosition = { y: touchStartEvent.touches[0].clientY };

        let startY = touchStartEvent.touches[0].clientY;
        const handleTouchMoveRef = (touchMoveEvent: TouchEvent) => {
            const height = startSize - startPosition.y + touchMoveEvent.touches[0].clientY;
            // currentActiveNodeHeight = height;

            editableNode = changeStyleByValue(
                'height',
                height + 'px',
                editableNode,
                activeNode.isMobileStyleContainerSize
            );

            setCurrentActiveNodeHeight(height)
            getActiveTag.style.setProperty('height', height + 'px', 'important')
            if (onChange)
                onChange(height,editableNode)
            // console.log(editableNode.customStyleMobile['height'])
            // dispatch(changeNodeNoSendRequest({activeNode, editedNode: editableNode}));
            // dispatch(changeActiveModalNode(editableNode));

            const moveY = touchMoveEvent.touches[0].clientY;
            const scrollY = startY - moveY;
            window.scrollBy(0, scrollY);
            startY = moveY;

        };

        const handleTouchEndRef = () => {
            document.body.removeEventListener('touchmove', handleTouchMoveRef);
            document.body.removeEventListener('touchend', handleTouchEndRef);
            // nodeToSave &&
            dispatch(changeActiveModalNode(editableNode));
            dispatch(changeNode({activeNode, editedNode: editableNode}));
        };

        // const nodeToSave = null;
        document.body.addEventListener('touchmove', handleTouchMoveRef);
        document.body.addEventListener('touchend', handleTouchEndRef, { once: true });
    };


    const onMouseDown = (mouseEvent: React.MouseEvent<HTMLButtonElement>) => {
        const startSize = sizeRef.current;
        const startPosition = {y: mouseEvent.clientY};
        const onMouseMove = (mouseMoveEvent: MouseEvent) => {
            if (mouseMoveEvent.buttons === 0) {
                onMouseUp()
                return;
            }

            const height = startSize - startPosition.y + mouseMoveEvent.clientY;
            // currentActiveNodeHeight = height;

            editableNode = changeStyleByValue(
                'height',
                height + 'px',
                editableNode,
                activeNode.isMobileStyleContainerSize
            );
            setCurrentActiveNodeHeight(height)
            getActiveTag.style.setProperty('height', height + 'px', 'important')
            if (onChange)
                onChange(height, editableNode)
            // console.log(editableNode.customStyleMobile['height'])
            // dispatch(changeNodeNoSendRequest({activeNode, editedNode: editableNode}));
            // dispatch(changeActiveModalNode(editableNode));
        };

        const onMouseUp = () => {
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('mouseup', onMouseUp);
            // nodeToSave &&
            dispatch(changeActiveModalNode(editableNode));
            dispatch(changeNode({activeNode, editedNode: editableNode}));
        };
        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, {once: true});

    };


    return (
        <div>
            <button className="btn btn-sm text-primary btn-outline-primary bg-white p-1" type="button"
                    onTouchStart={handleTouchStart} onMouseDown={onMouseDown}
            >
                <i className="bi bi-arrows-expand"></i> {currentActiveNodeHeight} {activeNode.isMobileStyleContainerSize && isMobileViewDimension ?
                <i className="bi bi-phone me-2"></i> : ''}
            </button>
        </div>
    );
}

export default DragCornerMobile;