/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetPageDocument = gql`
    query GetPage($id: Int!) {
  page(id: $id) {
    id
    title
    type
    contentString
    parentTemplate {
      id
      templates {
        id
        type
        content
        img
      }
    }
    user {
      userName
      roles
      email
    }
    userAccesses {
      accessType
      user {
        email
        roles
        userName
      }
    }
    folder {
      id
    }
  }
}
    `;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables> & ({ variables: GetPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export function useGetPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageSuspenseQueryHookResult = ReturnType<typeof useGetPageSuspenseQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const UpdatePageDataDocument = gql`
    mutation UpdatePageData($id: Int!, $title: String, $seoTitle: String) {
  updatePage(id: $id, title: $title, seoTitle: $seoTitle) {
    title
  }
}
    `;
export type UpdatePageDataMutationFn = Apollo.MutationFunction<UpdatePageDataMutation, UpdatePageDataMutationVariables>;

/**
 * __useUpdatePageDataMutation__
 *
 * To run a mutation, you first call `useUpdatePageDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageDataMutation, { data, loading, error }] = useUpdatePageDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      seoTitle: // value for 'seoTitle'
 *   },
 * });
 */
export function useUpdatePageDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageDataMutation, UpdatePageDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageDataMutation, UpdatePageDataMutationVariables>(UpdatePageDataDocument, options);
      }
export type UpdatePageDataMutationHookResult = ReturnType<typeof useUpdatePageDataMutation>;
export type UpdatePageDataMutationResult = Apollo.MutationResult<UpdatePageDataMutation>;
export type UpdatePageDataMutationOptions = Apollo.BaseMutationOptions<UpdatePageDataMutation, UpdatePageDataMutationVariables>;
export const PageCategoryDocument = gql`
    query PageCategory {
  pageCategory {
    id
    title
    pages {
      id
      type
      title
      image
    }
  }
}
    `;

/**
 * __usePageCategoryQuery__
 *
 * To run a query within a React component, call `usePageCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageCategoryQuery(baseOptions?: Apollo.QueryHookOptions<PageCategoryQuery, PageCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageCategoryQuery, PageCategoryQueryVariables>(PageCategoryDocument, options);
      }
export function usePageCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageCategoryQuery, PageCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageCategoryQuery, PageCategoryQueryVariables>(PageCategoryDocument, options);
        }
export function usePageCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageCategoryQuery, PageCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageCategoryQuery, PageCategoryQueryVariables>(PageCategoryDocument, options);
        }
export type PageCategoryQueryHookResult = ReturnType<typeof usePageCategoryQuery>;
export type PageCategoryLazyQueryHookResult = ReturnType<typeof usePageCategoryLazyQuery>;
export type PageCategorySuspenseQueryHookResult = ReturnType<typeof usePageCategorySuspenseQuery>;
export type PageCategoryQueryResult = Apollo.QueryResult<PageCategoryQuery, PageCategoryQueryVariables>;
export type GetPageQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetPageQuery = { __typename?: 'Query', page: { __typename?: 'GraphPage', id?: number | null, title?: string | null, type?: number | null, contentString: string, parentTemplate?: { __typename?: 'GraphPage', id?: number | null, templates: Array<{ __typename?: 'GraphTemplate', id?: number | null, type?: number | null, content?: string | null, img?: string | null }> } | null, user: { __typename?: 'User', userName: string, roles: Array<string>, email?: string | null }, userAccesses: Array<{ __typename?: 'UserAccess', accessType?: number | null, user?: { __typename?: 'User', email?: string | null, roles: Array<string>, userName: string } | null }>, folder?: { __typename?: 'GraphFolder', id: number } | null } };

export type UpdatePageDataMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  seoTitle?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdatePageDataMutation = { __typename?: 'Mutation', updatePage: { __typename?: 'GraphPage', title?: string | null } };

export type PageCategoryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PageCategoryQuery = { __typename?: 'Query', pageCategory?: Array<{ __typename?: 'GraphPageCategory', id?: number | null, title?: string | null, pages: Array<{ __typename?: 'GraphPage', id?: number | null, type?: number | null, title?: string | null, image?: string | null }> }> | null };
