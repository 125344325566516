import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Modal, Tooltip} from "bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {addNode, deleteNode} from "../../reducers/nodes";
import {
    changeHidden,
    editBarHotkeys,
    findNodeWhichAreContainer,
    handlerCopy,
    moveBackAction,
    moveForwardAction,
    parentExistsFunc,
    parentPlusButtonClick,
    pasteCopyNode,
    pasteCopyNodeAbove,
    pasteCopyNodeBelow
} from "../../service/EditBarService"
import {changeActiveModalNode} from "../../reducers/node-modal";
import {useHotkeys} from "react-hotkeys-hook";
import ExportNode from "./ExportNode";
import DragCorner from "../context-menu/block/modules/DragCorner";
import UpdateTemplate from "../context-menu/Template/UpdateTemplate";
import UserService from "../../service/User/UserService";
import {UserRole} from "../../types/User";
import {RootState} from "../../store";
import {PageNode} from "../../types/PageNode";
import {getNodeWithNewIds} from "../../service/NodeService";
import { openModal } from "../../reducers/modalSlice";

// import AlertModal from "../context-menu/modals/AlertModal";
// import {closeModal, openModal} from "../../reducers/modalSlice";

interface InputProps {
    activeNode: PageNode,
}

const EditBar: React.FC<InputProps> = ({activeNode}) => {
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const [nodeState, setNodeState] = useState({...activeNode})
    const activeContainerNode = useSelector((state: RootState) => state.activeContainerNode.activeContainerNode);
    const dispatch = useDispatch()
    let elements: any[] = [];
    let parentExists = true;
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode)
    const [blockIndentHeight, setBlockIndentHeight] = useState(null);
    const windowWidth = useRef(window.innerWidth);
    // const activeModalName = useSelector((state) => state.modal.activeModal);
    // const ChangeVisibilityModalName = "change-visibility-modal";

    const getAbsoluteOffset = (element: any, set: any) => {
        let offsetTop = 0;

        while (element) {
            offsetTop += element[set];
            element = element.offsetParent;
        }

        return offsetTop;
    }

    parentExistsFunc(activeNode, nodes, parentExists, elements)

    let getActiveTag = document.getElementById("blockContent" + activeNode.id)
    useLayoutEffect(() => {
        let activeTagOffsetTop = getAbsoluteOffset(getActiveTag, 'offsetTop')
        if (getActiveTag) {
            let blockHeight = getActiveTag.clientHeight - 10
            if (blockHeight < 30) {
                blockHeight = getActiveTag.clientHeight + 5
            }
            const activeTagOffsetCetner = getActiveTag.offsetWidth / 2;
            let blockLeft = getActiveTag.offsetLeft + activeTagOffsetCetner;
    
            setBlockIndentHeight({
                top: activeTagOffsetTop + blockHeight + 20 + "px",
                left: blockLeft + "px",
                height: '37px',
                transform: 'translateX(-50%)',
            });
        }
    }, [activeNode, getActiveTag])

    editBarHotkeys(useHotkeys, activeNode, dispatch, nodes)

    useLayoutEffect(() => {
        const editBlock: any = document.querySelector('.absolute-edit-block');
        if (editBlock) {
            const editBlockRect = editBlock.getBoundingClientRect();
            const parentPlusButton = editBlock.closest('.parent-plus-button');
            if (editBlockRect.right >= windowWidth.current && !parentPlusButton) {
                setBlockIndentHeight({...blockIndentHeight, right: "10px", left: undefined, transform: undefined});
            } else if (editBlockRect.left < 0 && !parentPlusButton) {
                setBlockIndentHeight({...blockIndentHeight, left: "10px", transform: undefined});
            }
        }
    }, [blockIndentHeight]);

    console.log(activeNode.template)
    // const handleClick = () => {
    //     dispatch(openModal({ modalName: ChangeVisibilityModalName }));
    // };

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl: any) {
            const tooltip = new Tooltip(tooltipTriggerEl, {
                trigger: 'hover'
            });

            tooltipTriggerEl.addEventListener('click', () => {
                setTimeout(() => {
                    tooltip.hide();
                }, 100);
            });

            return tooltip; 
        });

        return () => {
            document.querySelectorAll(".tooltip-custom").forEach(item => item.classList.remove("show"))
        }
    }, []);

    return (
        <>
            {getActiveTag &&
                <div className="position-absolute d-flex absolute-edit-block" style={blockIndentHeight}
                     onClick={(e) => {
                         e.stopPropagation();
                     }}>
                    <div className="border rounded border-primary d-flex bg-white">
                        <div className="d-flex align-items-center" data-bs-toggle="tooltip"
                             data-bs-placement="bottom" data-bs-title="Add Block"
                             data-bs-custom-class="tooltip-custom">
                            <button type="button" className="mx-2 btn btn-sm px-1 py-0 border-0 btn-outline-primary-plus"
                                    onClick={() => {
                                        if (activeNode.disallowedAddElements) {
                                            let containerNode = findNodeWhichAreContainer(activeNode, nodes[0]);
                                            dispatch(changeActiveModalNode(containerNode));
                                        }
                                        Modal.getOrCreateInstance('#container-to-add-modal').show();
                                    }}>
                                <i className="bi bi-plus-lg "></i>
                            </button>
                        </div>
                        <div className="vl bg-primary"></div>
                        {/*<div className="pe-2">*/}
                        {/*    <button type="button" className="btn btn-sm btn-warning text-white" onClick={(e) => {*/}
                        {/*        e.stopPropagation();*/}
                        {/*        Modal.getOrCreateInstance('#list-of-elements-to-add-modal').show();*/}
                        {/*    }}>*/}
                        {/*        <i className="bi bi-window-plus"></i> Add container*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        {/*{elements.length >= 0 ? (*/}
                        {/*    <ul className="navbar-nav">*/}
                        {/*        <li className="nav-item dropdown pe-2">*/}
                        {/*        <span className="btn btn-sm btn-primary dropdown-toggle"*/}
                        {/*              data-bs-toggle="dropdown"*/}
                        {/*              aria-expanded="false" onClick={(e) => {*/}
                        {/*            e.stopPropagation()*/}
                        {/*            e.preventDefault();*/}
                        {/*        }}>*/}
                        {/*            {activeNode.tagName} {activeNode.className}*/}
                        {/*        </span>*/}
                        {/*            <ul className="dropdown-menu">*/}
                        {/*                {elements.map(element => {*/}
                        {/*                    let braces = '';*/}
                        {/*                    if (element.className) {*/}
                        {/*                        braces = <>({element.className})</>;*/}
                        {/*                    }*/}
                        {/*                    return (*/}
                        {/*                        <li key={element.id}>*/}
                        {/*                        <span*/}
                        {/*                            className="dropdown-item"*/}
                        {/*                            onClick={(e) => {*/}
                        {/*                                e.stopPropagation();*/}
                        {/*                                dispatch(changeActiveModalNode(element))*/}
                        {/*                            }}*/}
                        {/*                        >*/}
                        {/*                            <>{element.tagName} {braces}</>*/}
                        {/*                        </span>*/}
                        {/*                        </li>*/}
                        {/*                    )*/}
                        {/*                })}*/}
                        {/*            </ul>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*) : (<></>)}*/}

                        <div className="d-flex align-items-center" data-bs-toggle="tooltip"
                             data-bs-placement="bottom" data-bs-title="Block Up"
                             data-bs-custom-class="tooltip-custom">
                            <button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0"
                                    onClick={() => {
                                        moveBackAction(dispatch, activeNode, nodes)
                                    }}><i className="bi bi-arrow-bar-up"></i>
                            </button>
                        </div>

                        <div className="d-flex align-items-center" data-bs-toggle="tooltip"
                             data-bs-placement="bottom" data-bs-title="Block Down"
                             data-bs-custom-class="tooltip-custom">
                            <button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0"
                                    onClick={() => {
                                        moveForwardAction(dispatch, activeNode, nodes)
                                    }}><i className="bi bi-arrow-bar-down"></i>
                            </button>
                        </div>

                        <div className="vl bg-primary"></div>

                        <div className="d-flex align-items-center" data-bs-toggle="tooltip"
                             data-bs-placement="bottom" data-bs-title="Edit"
                             data-bs-custom-class="tooltip-custom">
                            <button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        Modal.getOrCreateInstance('#edit-block-modal').show()
                                        dispatch(openModal({modalName: "edit-block-modal"}))
                                    }}>
                                <i className="bi bi-pencil"></i>
                            </button>
                        </div>
                        <div className="vl bg-primary"></div>
                        <div className="dropdown d-flex align-items-center">
                        <span className="mx-2 bg-secondary-light px-1 rounded" role="button" data-bs-toggle="dropdown"
                              aria-expanded="false">
                            <i className="bi bi-three-dots text-primary"></i>
                        </span>

                            <ul className="dropdown-menu bg-primary">
                                <li>
                                    <span className="dropdown-item bg-primary">
                                    <button type="button" className="text-start w-100 btn btn-sm btn-primary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                let copyOfActive = {...activeNode}
                                                copyOfActive = getNodeWithNewIds(copyOfActive)
                                                dispatch(addNode({
                                                    activeNode: {id: activeNode.parentId},
                                                    newNode: copyOfActive
                                                }))
                                            }}>
                                        <i className="bi bi-stickies me-2"></i>Duplicate Content
                                    </button>
                                    </span>
                                </li>
                                <li>
                                    <span className="dropdown-item bg-primary">
                                <button type="button" className="text-start w-100 btn btn-sm btn-primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlerCopy(activeNode);
                                        }}><i className="bi bi-stickies me-2"></i>Copy Content</button>
                            </span>
                                </li>
                                <li><span className="dropdown-item bg-primary">
                                {activeNode ?
                                    (<button type="button" className="text-start btn btn-sm btn-primary w-100"
                                             onClick={() => {
                                                    dispatch(deleteNode({removeNode: activeNode}));
                                                    dispatch(changeActiveModalNode(undefined));
                                                    handlerCopy(activeNode);
                                             }}><i className="bi bi-scissors"></i> Cut</button>) :
                                    (<button type="button" className="text-start btn btn-sm btn-primary w-100"><i
                                        className="bi bi-scissors me-2"></i>Cut Content
                                    </button>)
                                }
                            </span></li>
                                <li>
                                    <div className="dropdown-item bg-primary">
                                        {activeNode ?
                                            (<button type="button" className="text-start btn btn-sm btn-primary w-100"
                                                     onClick={(e) => {
                                                        e.stopPropagation();
                                                        dispatch(deleteNode({removeNode: activeNode}));
                                                        dispatch(changeActiveModalNode(undefined));
                                                     }}><i className="bi bi-trash me-2"></i>Delete Content
                                                </button>
                                            )
                                            :
                                            (<button type="button" className="text-start btn btn-sm btn-primary w-100">
                                                <i className="bi bi-trash"></i>
                                            </button>)
                                        }
                                    </div>
                                </li>

                                {nodeState.tagName !== "img" && isAdvancedMode &&
                                    <li>
                                        <span className="dropdown-item bg-primary">
                                            <button type="button"
                                                    className="text-start w-100 btn btn-sm btn-primary"
                                                    onClick={() => changeHidden(nodeState, setNodeState, dispatch)}>
                                                {nodeState.hidden === 'desktopHidden' ?
                                                    <>
                                                        <i className="bi bi-eye me-2"></i>
                                                        Show Content
                                                    </> :
                                                    <>
                                                        <i className="bi bi-eye-slash me-2"></i>
                                                        Hide Content
                                                    </>
                                                }
                                            </button>
                                        </span>
                                    </li>
                                }

                                <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                 <button type="button" className="text-start btn btn-sm btn-primary w-100"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             pasteCopyNodeAbove(dispatch, activeNode, nodes);
                                         }}><i className="bi bi-arrow-bar-up me-2"></i> Paste Above Block
                             </button>
                            </span></li>
                                <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                 <button type="button" className="text-start btn btn-sm btn-primary w-100"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             pasteCopyNode(dispatch, activeNode);
                                         }}><i className="bi bi-clipboard-check me-2"></i> Paste in block
                             </button>
                            </span></li>
                                <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                 <button type="button" className="btn btn-sm btn-primary w-100" onClick={(e) => {
                                     e.stopPropagation();
                                     pasteCopyNodeBelow(dispatch, activeNode, nodes);
                                 }}><i className="bi bi-arrow-bar-down me-2"></i> Paste Below Block
                             </button>
                            </span></li>
                                <li>
                                    <span className="dropdown-item bg-primary">
                                        <ExportNode activeNode={activeNode}/>
                                    </span>
                                </li>
                                {activeNode.template !== undefined && (!activeNode.template.isCommon || activeNode.template.isCommon && UserService.getInstance().user.roles.includes(UserRole.ROLE_ADMIN)) ?
                                    <li>
                                        <span className="dropdown-item bg-primary">
                                            <UpdateTemplate activeNode={activeNode}/>
                                        </span>
                                    </li> : ''}
                            </ul>
                        </div>
                    </div>
                    {activeNode.className !== "container-fluid" && <div className="d-flex align-items-center border-primary border ms-2 rounded bg-white"  data-bs-toggle="tooltip"
                         data-bs-placement="bottom" data-bs-title="Add Column"
                         data-bs-custom-class="tooltip-custom">
                        <button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0"
                                onClick={() => {parentPlusButtonClick(dispatch, activeContainerNode, Modal, "#edit-block-modal")}}>
                            <i className="bi bi-columns-gap"></i>
                        </button>
                    </div>}
                    <DragCorner activeNode={activeNode} getActiveTag={getActiveTag}  onChange={(height,changedNode)=> setNodeState(changedNode)}/>
                </div>

            }
        </>
    );
};

export default EditBar;